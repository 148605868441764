



















































@import '~@tko/src/styles/variables/variables';
$size: 60px;

.w-spinner {
  width: $size;
  height: $size;
  border-radius: 100%;
  position: relative;

  .circle {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border-style: solid;
    border-color: transparent;
  }

  .circle-1 {
    border-top-color: $tw-slate;
    animation: w-spinner-animation 1s infinite;
  }

  .circle-2 {
    border-bottom-color: $tw-slate;
    animation: w-spinner-animation 1s infinite alternate;
  }
}

@keyframes w-spinner-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
