














































































































@import '~@tko/src/styles/variables/variables';

.passive-help-popover {
  position: fixed;
  left: 60px;
  top: 110px;
  z-index: 199;
  max-width: 100%;
  max-width: 80vw;
  max-height: 80vh;

  ::v-deep .w-modal-dialog {
    overflow: auto;
    width: 862px;
    max-width: 80vw;
    max-height: 80vh;
    min-width: 280px;
    border-radius: 8px;
    box-shadow: 0px 8px 24px rgba(11, 14, 31, 0.16);
    flex-shrink: 0;

    &__body {
      padding: 0px 20px;
    }
    &__header {
      padding: 12px;
    }
    &__footer {
      padding: 0px 0px 24px;
    }
  }

  &__footer {
    padding: 16px 0px 0px;
  }

  &__content {
    display: flex;
    flex-direction: row;
    @media (max-width: 600px) {
      flex-direction: column;
    }
  }

  &__image {
    display: flex;
    justify-content: center;
  }

  &__btn-read-more {
    color: #4461d7;
    background: none;
    border: none;
    padding: 0px 0px;
    margin-left: 32px;
  }
}

.description {
  font-size: 14px;
  line-height: 150%;
}

.title {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
}
