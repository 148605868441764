


































































































































































input:focus-within:checked + span::after {
  content: '';
  background-color: var(--token-c-radio-color-active-strongest);
}
