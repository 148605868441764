@mixin scrollbar {
    -ms-overflow-style: -ms-autohiding-scrollbar;

    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    &:-webkit-scrollbar-corner {
        display: none;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0);
        background-clip: initial;
        border: 0;
        min-height: initial;
        padding: initial;
        box-shadow: none;
    }

    &:hover::-webkit-scrollbar-thumb {
        background-color: rgba(66, 82, 110, 0.36);
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: rgba(66, 82, 110, 0.56);
    }

    &::-webkit-scrollbar-track-piece {
        box-shadow: none;
        border-width: 0;
        background-color: transparent;
        border: 0;
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: rgba(66, 82, 110, 0.36) rgba(255, 255, 255, 0);
    }
}