







































@import '@/styles/components/widgets/_w-tooltip.scss';

.task-row {
  display: flex;
  margin-bottom: 16px;
  color: #0b0e1f;
  height: 28px;
  max-height: 28px;
  font-size: 14px;

  &.no-max-height {
    height: initial;
    max-height: initial;
  }

  &.centered {
    align-items: center;
  }

  &__label {
    width: 120px;
    margin-right: 16px;
    color: #6d6f80;
    flex-shrink: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__content {
    flex-grow: 2;
  }

  &::v-deep .assignees {
    display: inline-flex;
    width: auto;
    justify-content: flex-start;
    padding-left: 0;
  }
}
