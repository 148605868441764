



























































































































































































































































































































































































































































































































































.v-enter-active,
.v-leave-active {
  transition: opacity 0.25s ease;
}

.v-enter,
.v-leave-to {
  opacity: 0;
}
