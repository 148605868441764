// sass-lint:disable class-name-format force-element-nesting no-qualifying-elements no-important

@import '~@tko/src/styles/variables/variables';

$input-background-color: $neutral-20;
$preview-border-color: #e1e1e1;
$focus-color: #4461d7;
$button-active-background: #eef1f6;
$text-color--dark: #0b0e1f;
$divider-color: #6d6f80;
$border-radius: 4px;

.w-markdown-editor-vue {
    $self: &;

    &--quiet {
        .CodeMirror {
            transform: translate(0, -42px);
            transition: transform 250ms ease-in, border-color 250ms ease-in, border-radius 250ms ease-in;
            transition-delay: 250ms;
        }

        .CodeMirror-focused {
            transform: translate(0, -1px);
        }

        .w-easy-markdown-editor--preview-active {
            .CodeMirror {
                transform: translate(0, -1px);
                border: 1px solid $preview-border-color;
            }
        }

        &#{$self}--wizard {
            .CodeMirror-focused {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                border-top-color: transparent;
            }
        }
    }

    &--wizard {
        .CodeMirror {
            border-radius: $border-radius;
            border-color: transparent;
            background-color: $input-background-color;
            font-size: 14px;
            color: $text-color--dark;

            &-focused {
                border-radius: $border-radius;
                border: 1px solid $focus-color;
            }
        }

        .w-easy-markdown-editor--preview-active {
            .CodeMirror {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                border-color: $focus-color !important;
                border-top-color: transparent !important;
            }
        }

        .editor-toolbar {
            border-radius: 0;
            border-top-left-radius: $border-radius;
            border-top-right-radius: $border-radius;
            border-color: $focus-color;

            .w-easy-markdown-editor__button {
                color: $text-color--dark;

                &.active,
                &:hover {
                    background: $button-active-background;
                    border-color: transparent;
                }
            }

            i.seperator {
                border-left-color: $divider-color;
            }
        }
    }
}
