































































































































































































































.pubble-app {
  &.pubble-app--hide {
    display: none !important;
  }

  &.pubble-app--remove-transition {
    .pubble-sb-bounce-in {
      animation: none;
      animation-delay: 0s;
      animation-name: none;
    }

    .pubble-sb-animated {
      animation: none;
      animation-delay: 0s;
      animation-name: none;
    }
  }

  .pubble-cw-powered-container {
    display: none;
  }
}

.pubble-chat-icon-close {
  position: fixed;
  border: 0px solid #f5f7fa;
  padding: 0px;
  background: #3b93f7;
  border-radius: 50%;
  fill: #fff;
  z-index: 9995;
  font-size: 17px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  right: 20px;
  bottom: 77px;
  animation-name: bounce-in;
  animation-fill-mode: both;
  animation-duration: 0.5s;
}

.pubble-chat-icon-close:hover {
  background: #005ac1;
}

@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }

  50% {
    opacity: 1;
    transform: scale(1.05);
  }

  70% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

.pubble-chatify-icon-close--hide {
  display: none;
}

.pubble-chat-icon-close--remove-transition {
  animation: none;
  animation-delay: 0s;
  animation-name: none;
}
