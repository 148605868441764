































































































































































































@import '~@tko/src/styles/variables/variables';

.table-cell-privacy-column {
  &.privacy-padding {
    padding: 0 10px;
  }
}
.privacy-label {
  margin-top: 3px;
  display: flex;
  align-items: center;
  position: relative;
  color: white;
  border-radius: 10px;
  line-height: 1;
  user-select: none;
  cursor: pointer;
  &.cursor-wait {
    cursor: wait;
  }
  &.privacy-label-bg {
    background-color: #ed818e;
  }
  &.privacy-label-padding {
    padding: 3px 6px;
  }
  .lock-icon {
    width: $font-size--btn !important;
    height: $font-size--btn !important;
  }
  .privacy-label-text {
    margin-left: $margin--small;
  }
}

.privacy-popover {
  min-width: 200px;
  max-width: 280px;
  padding: $padding--large;
  .privacy-popover-header {
    font-size: $font-size--btn;
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .privacy-popover-inherited {
      color: #8f9bb3;
    }

    .btn {
      padding: 0px;
      color: #4461d7;

      &:hover {
        color: #4461d7;
        text-decoration: underline;
      }
    }
  }
  .privacy-popover-title {
    color: $color--text-tinted-dark;
    font-size: $font-size--default;
    font-weight: $font-weight--semibold;
    margin-bottom: 0;
    margin-bottom: 0;
  }
  .privacy-popover-list {
    list-style-type: none;
    font-size: $font-size--default;
    max-height: 50vh;
    overflow-y: auto;
    padding: 0;
    li {
      padding-top: 12px;
      display: flex;
      align-items: center;
      color: $color--text-tinted-dark;
      &:last-child {
        border: 0;
      }
    }
    .popover-name {
      max-width: 120px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .popover-company-name {
      color: #646d7d;
    }
    .popover-avatar {
      margin-right: $margin--medium;
      height: 28px;
      width: 28px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        border-radius: 50%;
        height: 100%;
        width: inherit;
      }
    }
  }
}
