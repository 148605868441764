









































































































@import './tko/src/styles/variables/variables';

.ellipsis-label-label {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.multi-line {
  white-space: wrap;
  overflow: hidden;
  -webkit-line-clamp: var(--lines);
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
}

.ellipsis-label {
  overflow: hidden;

  &--popover {
    padding: $padding--small $padding--medium;
    color: white;
    background: black;
    border-radius: $border-radius--default;
  }
  .trigger {
    white-space: initial;
    width: 100%;
    max-width: 100%;
    -webkit-line-clamp: var(--lines);
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
  }
}

.max-width {
  max-width: 400px;
  text-align: center;
}
