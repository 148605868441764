
// Config to allow overwrite properties of base classes

// Globals
// ==================================================
$default-separator: "--";
$is-important: "!important";

// Border
// ==================================================
$border-separator: $default-separator;
$border-list: (
    default: 1px solid #ddd #{$is-important},
    white: 1px solid #fff #{$is-important},
    thick: 3px solid #ddd #{$is-important},
    none: none #{$is-important}
);

// Background
// ==================================================
$background-separator: $default-separator;

// Border Color
// ==================================================
$border-color-separator: $default-separator;
$border-color-list: (
    default: #dfdfdf #{$is-important},
    accent: #43c86f #{$is-important},
    white: #fff #{$is-important}
);

// Border Radius
// ==================================================
$radius-separator: $default-separator;
$radius-list: (
     (none, 0, #{$is-important}),
     (small, 3px, #{$is-important}),
     (medium, 5px, #{$is-important}),
     (large, 8px, #{$is-important})
);

// Cursor
// ==================================================
$cursor-separator: $default-separator;
$cursor-list: (
    move: move #{$is-important},
    pointer: pointer #{$is-important},
    default: default #{$is-important}
);

// Color
// ==================================================
$color-separator: $default-separator;
$color-list: (
    inherit: inherit #{$is-important},
    gray: #666 #{$is-important},
    gray-dark: #353535 #{$is-important},
    gray-light: #9e9e9e #{$is-important},
    muted: #787878 #{$is-important},
    heading: $color--heading #{$is-important},
    starred: $color--star-selected
);

// Font Size
// ==================================================
$font-size-separator: $default-separator;
$font-size-list: (
    tiny: 10px #{$is-important},
    small: 12px #{$is-important},
    medium: 16px #{$is-important},
    large: 22px #{$is-important},
    default: 13px #{$is-important},
    inherit: inherit #{$is-important}
);

// Font Weight
// ==================================================
$font-weight-separator: $default-separator;
$font-weight-list: (
    light: 300,
    semibold: 600,
    bold: 700,
    normal: 400
);

// Flexbox
// ==================================================
$flex-order-separator: $default-separator;
$flex-order-list: (
    "0": 0,
    "1": 1,
    "2": 2,
    last: 9999
);

// Layout
// ==================================================
$overflow-separator: $default-separator;

// Margin
// ==================================================
$margin-separator: $default-separator;
$margin-list: (
    tiny: 2px #{$is-important},
    small: 4px #{$is-important},
    medium: 8px #{$is-important},
    large: 16px #{$is-important},
    xlarge: 20px #{$is-important},
    xxlarge: 24px #{$is-important},
    auto: auto #{$is-important},
    none: 0 #{$is-important}
);

// Padding
// ==================================================
$padding-separator: $default-separator;
$padding-list: (
    tiny: 2px #{$is-important},
    small: 4px #{$is-important},
    medium: 8px #{$is-important},
    large: 16px #{$is-important},
    xlarge: 20px #{$is-important},
    none: 0 #{$is-important}
);

// Text Align
// ==================================================
$text-align-separator: $default-separator;

// Text Decoration
// ==================================================
$text-decoration-separator: $default-separator;
$text-decoration-list: (
    "underline": underline #{$is-important},
    "none": none #{$is-important},
    "line-through": line-through #{$is-important}
);

// Vertical Align
// ==================================================
$vertical-align-separator: $default-separator;

// Width
// ==================================================
$width-separator: $default-separator;
$width-list: (
    "100": 100%,
    "95": 95%,
    "90": 90%,
    "85": 85%,
    "80": 80%,
    "75": 75%,
    "70": 70%,
    "65": 65%,
    "60": 60%,
    "55": 55%,
    "50": 50%,
    "45": 45%,
    "40": 40%,
    "35": 35%,
    "30": 30%,
    "25": 25%,
    "20": 20%,
    "15": 15%,
    "10": 10%,
    "inherit": inherit
);

// Max-Width
// ==================================================
$max-width-separator: $default-separator;
$max-width-list: (
    "100": 100% #{$is-important},
    "50": 50% #{$is-important}
);

// Height
// ==================================================
$height-separator: $default-separator;
$height-list: (
    "100": 100% #{$is-important},
    "50": 50% #{$is-important},
    "inherit": inherit #{$is-important}
);

// Z Index
// ==================================================
$z-index-separator: $default-separator;
$z-index-list: (
    negative: -1,
    "1": 1,
    "50": 50,
    "100": 100
);
