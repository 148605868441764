//
// List Loading Widget
//

@import '~@tko/src/styles/variables/variables';
@import '@/styles/mixins/_preloader.mixin.scss';


// Variables
$color__w-list-loading__circle: #c5c5c5;


//  Structure
//  ----------

.w-list-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 7vh 0;

    $self: &;

    &__circle {
        @include preloader(60px, 5px, $color__w-list-loading__circle);
        border-top-color: #4461d7; // Use 'Indigo 40' design token when available;
        margin-bottom: $margin--large;

        &--small {
            font-size: 30px;
            border-width: 3px;
        }
    }

    &__label {
        font-size: 1rem;
        color: $color__w-list-loading__circle;
        font-weight: 400;
    }

    &--inline {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 5px 0;

        #{$self}__circle {
            @include preloader(20px, 2px, $color__w-list-loading__circle);
            margin-right: 10px;
            margin-bottom: 0;
        }

        #{$self}__label {
            margin-bottom: 0;
        }
    }

    &--delayed {
        opacity: 0;
        animation: fadeIn 1s;
        animation-delay: 1.5s;
        animation-fill-mode: forwards;

        @keyframes fadeIn {
            from {
                opacity: 0;
            }

            to {
                opacity: 1;
            }
        }
    }

}
