// sass-lint:disable no-mergeable-selectors
@import '~@tko/src/styles/variables/variables';
// *
// *
//  Toggle Switch
// *
// *

//  Variables
//  ----------

$background--toggle-switch__toggle: #d9d9d9;
$box-shadow--toggle-switch__toggle: 0 1px 0 rgba(255, 255, 255, 0.5),
  inset 0 1px 1px rgba(0, 0, 0, 0.1);

$background--toggle-switch__toggle--before: #fff;
$box-shadow--toggle-switch__toggle--before: 0 1px 1px rgba(0, 0, 0, 0.25),
  0 0 2px rgba(0, 0, 0, 0.15);

$background--toggle-switch__toggle--active: #4461d7;

//  Structure
//  ----------

.w-toggle-switch {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  vertical-align: middle;
  cursor: pointer;
  margin: 0;
  padding: 0;
  font: inherit;
  color: inherit;
  line-height: normal;
  margin-bottom: 0;
}

//  Elements
//  ----------

.w-toggle-switch {
  &__toggle {
    display: inline-block;
    cursor: pointer;
    width: 40px;
    height: 16px;
    border-radius: 80px;
    background-color: $background--toggle-switch__toggle;
    position: relative;
    vertical-align: middle;
    box-shadow: $box-shadow--toggle-switch__toggle;
    transition: background-color 210ms ease;

    &::before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      background-color: $background--toggle-switch__toggle--before;
      box-shadow: $box-shadow--toggle-switch__toggle--before;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: -2px;
      transition: transform 120ms ease;
    }
  }

  &__checkbox {
    opacity: 0;
    position: absolute;
  }

  &__label {
    display: flex;
    float: left;
    cursor: inherit;
    padding-left: 7px;
    margin-right: 5px;
  }

  &__info-icon {
    fill: map-get($semantic-colors, 'info');
    margin-left: 5px;
    margin-right: 5px;
  }

  &__tooltip {
    max-width: 320px;
    color: map-get($color-list, 'gray-light');
  }

  &__read-only-check {
    fill: map-get($semantic-colors, 'positive');
  }

  &__read-only-close {
    fill: map-get($semantic-colors, 'negative');
  }
}

//  Modifiers
//  ----------

.w-toggle-switch {
  &--reversed-order {
    flex-direction: row-reverse;
  }

  &--disabled {
    opacity: 0.5;
  }

  &__toggle {
    &--on {
      background-color: $background--toggle-switch__toggle--active;

      &::before {
        transform: translateX(100%);
      }
    }

    &--disabled {
      cursor: not-allowed;
    }
  }
}
