











































































































































































































































































































































































@import '~@tko/src/styles/variables/dimensions/_typography.dimensions';
@import '~@tko/src/styles/variables/dimensions/_margins-paddings.dimensions';

.notifications {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  transform: translate3d(0, 0, 0);
  font-size: 11px;
  user-select: none;
}

.notifications__header {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-bottom: $margin--medium;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 16px;
}

.notifications__header-text {
  font-weight: $font-weight--semibold;
  font-size: $font-size--h1;
  line-height: $line-height--small;
  color: #0b0e1f; // Use 'Neutral 60' design token when available
  user-select: none;
}

.notifications__header-indicator {
  margin: 0 $margin--medium;
  flex: 0 0 auto;
}

.u-blank-slate {
  margin: 0;
  height: 100%;

  .blank-slate__image {
    max-height: 96px;
  }

  .mute-icon {
    width: 1.3rem;
    height: 1.3rem;
    max-width: 1.3rem;
  }
}
