



































































































.file-upload {
  border-color: #c5cee0;
  font-size: 14px;

  div span {
    color: #3f51b5;
    text-decoration: underline;
  }
}
