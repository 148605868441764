@import './tko/src/styles/variables/variables';

.table-cell-task {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  cursor: pointer;

  &.is-new {
    margin-left: -10px;
  }

  &.editing {
    padding-right: 10px;
  }

  &-name {
    min-width: 0;
    margin-left: 10px;
    margin-right: 12px;

    a,
    span {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }

    .table-cell-task-name-link {
      color: #0b0e1f;
      font-size: 1em;
      span {
        &.is-completed {
          text-decoration: line-through;
          &:hover {
            text-decoration: line-through;
            cursor: pointer;
          }
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .label {
    cursor: pointer;
  }
  .table-cell-task-lockdown {
    color: #6d6f80;
    display: flex;
    align-items: center;
    height: 28px;
    display: flex;
    justify-content: center;
    margin-right: 12px;
  }

  input {
    width: 100%;
    border: 1px solid #f5f7fa;
    padding: 7px 10px;
    border-radius: $border-radius--default;
    outline: none;
    margin-left: 10px;
  }
  &__form-container {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__add-btn {
    min-width: 20px;
    min-height: 20px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: 1px solid $neutral-80;
    border-radius: 50%;

    svg {
      font-size: 8px;
      pointer-events: none;
    }
  }

  &:hover {
    .table-cell-task-quick-view {
      display: flex;
    }

    .table-cell-task-name-edit {
      display: flex;
    }
  }
}
