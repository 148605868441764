


































































.layout-container {
  display: flex;
  --navigation-width--base: 208px;
  --navigation-width--minimized: 84px;
  --navigation-background: #0b0e1f;
}

.overflow-x-clipped {
  overflow-x: clip;
  width: 80%;
}

.layout-sidebar {
  color: #fff;
  background-color: var(--navigation-background);
  width: var(--navigation-width--base);
  height: 100vh;
  position: sticky;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 20;
  flex-shrink: 0;
}

.sidebar-minimized {
  width: var(--navigation-width--minimized);
}
