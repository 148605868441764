









































































$spacing: 2px;

.ToggleSwitch {
  position: relative;
  border-radius: 16px;
  background-color: theme('backgroundColor.palette-neutral.30');
  color: theme('backgroundColor.palette-neutral.30');
  caret-color: theme('backgroundColor.palette-neutral.30');
  outline: none;

  .ToggleSwitch__input {
    display: none;
  }

  .ToggleSwitch__control {
    position: absolute;
    top: $spacing;
    left: $spacing;
    transform: translateX(0);
  }

  .ToggleSwitch__dot {
    background-color: white;
    border-radius: 9999px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .ToggleSwitch__glow {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    transform: scale(1);
    border-radius: 9999px;
    opacity: 0.24;
  }

  &:not(.ToggleSwitch--disabled) {
    cursor: pointer;
  }

  &:hover,
  &:focus,
  &:active {
    .ToggleSwitch__glow {
      transform: scale(2);
    }
  }

  &.ToggleSwitch--disabled {
    cursor: not-allowed !important;
    background-color: theme('backgroundColor.palette-neutral.20') !important;

    &.ToggleSwitch--checked {
      .ToggleSwitch__control {
        transform: translateX(0) !important;
      }
    }

    &,
    &:hover,
    &:focus,
    &:active {
      .ToggleSwitch__glow {
        display: none !important;
      }
    }
  }

  &:hover {
    .ToggleSwitch__glow {
      background-color: theme('backgroundColor.palette-neutral.20');
    }
  }

  &:focus {
    .ToggleSwitch__glow {
      background-color: theme('backgroundColor.palette-neutral.30');
    }
  }

  &:active {
    .ToggleSwitch__glow {
      background-color: theme('backgroundColor.palette-neutral.40');
    }
  }

  &.ToggleSwitch--checked {
    background-color: theme('backgroundColor.palette-indigo.40');
    color: theme('backgroundColor.palette-indigo.40');
    caret-color: theme('backgroundColor.palette-indigo.40');

    &:hover {
      .ToggleSwitch__glow {
        background-color: theme('backgroundColor.palette-indigo.30');
      }
    }

    &:focus {
      .ToggleSwitch__glow {
        background-color: theme('backgroundColor.palette-indigo.40');
      }
    }

    &:active {
      .ToggleSwitch__glow {
        background-color: theme('backgroundColor.palette-indigo.50');
      }
    }
  }

  &,
  &.ToggleSwitch--sm {
    width: 28px;
    min-width: 28px;
    height: 16px;

    &.ToggleSwitch--checked {
      .ToggleSwitch__control {
        transform: translateX(calc(100% + #{$spacing * 2}));
      }
    }

    .ToggleSwitch__dot,
    .ToggleSwitch__glow {
      width: 12px;
      height: 12px;
    }
  }

  &.ToggleSwitch--lg {
    width: 40px;
    min-width: 40px;
    height: 20px;

    &.ToggleSwitch--checked {
      .ToggleSwitch__control {
        transform: translateX(calc(100% + #{$spacing * 2}));
      }
    }

    .ToggleSwitch__dot,
    .ToggleSwitch__glow {
      width: 16px;
      height: 16px;
    }
  }
}
