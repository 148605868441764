



































































.subtask-new {
  border: 1px solid #f5f7fa;
  padding: 14px 12px;
  padding-left: 32px;
  font-size: 12px;
  display: flex;
  align-items: center;

  button {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: 1px solid #c5cada;
    position: relative;
    background-color: #fff;

    &:after,
    &:before {
      content: '';
      position: absolute;
      background-color: #0b0e1f;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    &:before {
      width: 8px;
      height: 1px;
    }

    &:after {
      height: 8px;
      width: 1px;
    }
  }

  input {
    font-size: 14px;
    line-height: 24px;
    color: #0b0e1f;
    border: none;
    flex-grow: 2;
    outline: none;
    margin-left: 8px;

    &::placeholder {
      color: #6d6f80;
    }
  }
}
