




















































































































































































$self: '.w-option-picker';

.w-option-picker {
  & {
    display: inline-block;
  }

  &.block {
    &,
    .v-popover,
    .v-popover > div {
      display: block;
      width: 100%;
    }
  }

  .v-popover {
    .trigger {
      display: block !important;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    width: 100%;
    height: 100%;
  }

  &__current,
  &__option {
    cursor: pointer;
    border-radius: 3px;
    font-size: 12px;
    text-align: center;
    padding: 13px 15px;
    display: flex;
    align-items: center;
  }

  &__current {
    &.disabled {
      cursor: not-allowed;
    }
  }

  &__options {
    padding: 10px;
    box-sizing: content-box;
    overflow-y: auto;
    overflow-x: hidden;

    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: rgba(66, 82, 110, 0.36);
      }
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 6px;
    }
  }

  &__option {
    display: block;
    min-width: 150px;
    margin-bottom: 8px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.hasDot {
      text-align: left;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &-dot {
      display: inline-block;
      width: 8px;
      height: 8px;
      margin-right: 4px;
      border-radius: 4px;
      flex-shrink: 0;
    }

    &-label {
      display: inline-block;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.is-placeholder {
        color: #7d7d7d;
      }
    }
  }

  &.has-indicators {
    #{$self}__current {
      position: relative;

      &:before {
        content: '\F107';
        font-size: 18px;
        color: inherit;
        font-family: 'FontAwesome';
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        transition: transform 0.2s ease;
      }

      &.open {
        &:before {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }
  }

  &.has-dot {
    #{$self}__current {
      padding: 13px 0;
      text-align: left;
    }
  }
}
