










































































































































.TableViewColumnPickerFieldItems {
  list-style-type: none;
  padding: 5px 0 5px 0;
  max-height: 395px;
  overflow-y: auto;

  ul {
    padding-left: 0;
    max-height: 280px;
    overflow-y: auto;
  }

  &__edit-icon {
    cursor: pointer;
  }

  &--secondary {
    ul {
      max-height: 333px;
    }
  }
}
