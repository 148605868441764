@import '~@tko/src/styles/variables/variables';

.table-cell-date {
  padding: 8px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.disabled {
    pointer-events: none;
  }

  * {
    height: 100%;
    width: 100%;
  }

  &.muted {
    color: #aaa;
  }

  &.past {
    color: #E12D42;
  }

  &.today {
    color: #1B90FD ;
  }

  &.tomorrow {
    color: #FF7641;
  }

  &:hover,
  &.is-open {
    .trigger {
      cursor: pointer;
    }
    .date-label {
      &.task-details {
        color: $indigo-30;
      }
      .placeholder {
        color: $indigo-30;
      }
      .fallback-label {
        border-radius: $border-radius--default;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $white;
        color: $indigo-30;
        font-size: $font-size--btn;
      }
      .edit-icon__wrapper {
        display: flex;
      }
    }
  }

  .date-label {
    position: relative;
    display: flex;
    &:focus {
      .edit-icon__wrapper {
        display: flex;
      }
    }
    &.task-details {
      margin-left: -8px;
    }
  }

  .edit-icon__wrapper {
    display: none;
    align-items: center;
    justify-content: flex-end;
    width: 45px;
    height: 100%;
    padding-right: 5px;
    background: linear-gradient(90deg, transparent 0%, #F5F7FA 45%);
    position: absolute;
    right: 0;
    top: 0;
  }


  .placeholder {
    display: flex;
    align-items: center;
  }

  .fallback-label {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #4461d7;
    display: none;
    height: 100%;
  }

  .value {
    display: flex;
    justify-content: flex-start !important;
    align-items: center;

    &.has-value {
      &.value {
        display: block;
      }

      &:before {
        display: none;
      }
    }
  }
  .relative {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.date-label {
  &.task-details {
    &:hover,
    &.is-open {
      color: $indigo-30;
      cursor: pointer;
    }
  }
}

.table-cell-date-disabled-tooltip {
  .w-tooltip__arrow {
    display: none;
  }
}