



































































































































































































































































@import '~@tko/src/styles/variables/_variables';
@import '~@tko/src/styles/variables/dimensions/_typography.dimensions';
@import '~@tko/src/styles/variables/dimensions/_margins-paddings.dimensions';

.notifications-list {
  padding-bottom: 0;
}

.notifications-list--empty {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-bottom: 0;
}

.notifications-list__separator {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px; /* 24px gap; compensating for spacing within text */
  padding-bottom: 8px; /* 24px gap; compensating for spacing within below item */
}

.u-blank-slate {
  margin: 0;
  height: 100%;
}

/*************************** Transitions ***************************/
$transition-duration: var(--transition-duration);

/***************** Slide ******************/
.slide-left-move,
.slide-right-move {
  transition: transform $transition-duration;
}

.slide-left-leave-active {
  animation-name: slide-left;
  animation-duration: $transition-duration;
}

.slide-right-leave-active {
  animation-name: slide-right;
  animation-duration: $transition-duration;
}

@keyframes slide-left {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(-100%, 0);
  }
}

@keyframes slide-right {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(100%, 0);
  }
}

/***************** Shrink ******************/
.shrink-move {
  transition: transform $transition-duration;
}

.shrink-leave-active {
  animation-name: shrink;
  animation-duration: $transition-duration;
  position: absolute;
  width: calc(100% - 6px); // Compenstate for scroll bar width
}

@keyframes shrink {
  from {
    opacity: 1;
    transform-origin: top;
    transform: scale3d(1, 1, 1);
  }
  to {
    opacity: 0;
    transform-origin: top;
    transform: scale3d(0, 0, 0);
  }
}
