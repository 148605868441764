
























































.subtasks {
  .subtask {
    border: 1px solid #f5f7fa;
    border-bottom: 0;
    padding: 14px 12px;
    font-size: 12px;
    display: flex;
    align-items: center;

    &:last-child {
      border: 1px solid #f5f7fa;
    }

    &__title {
      font-size: 14px;
      line-height: 24px;
      color: #0b0e1f;
    }
  }
}
