












































































































































































@import '~@tko/src/styles/mixins/mixins';
@import '~@tko/src/styles/variables/variables';

.recent-project-list--scroll-container {
  @include scrollbar();
  // max-height: calc(100vh - 220px);
  overflow: auto;
}

::v-deep .LoadOnScroll {
  height: 100%;
}

::v-deep .LoaderState:first-child {
  height: 100%;
}
