












































































.collapse {
  &__body {
    will-change: max-height;
    overflow: hidden;
    transition: max-height 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
  }

  &.partial {
    position: relative;

    &.collapsed:after {
      content: '';
      background: #fff;
      background: -webkit-linear-gradient(
        to top,
        #fff 0%,
        rgba(255, 255, 255, 0.3) 120%
      );
      background: linear-gradient(
        to top,
        #fff 0%,
        rgba(255, 255, 255, 0.3) 120%
      );
      bottom: 0;
      left: 0;
      right: 0;
      height: 30px;
      position: absolute;
    }

    &.collapsed {
      .collapse__body {
        pointer-events: none;
      }

      &.clickable {
        cursor: pointer;
      }
    }
  }
}
