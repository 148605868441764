// sass-lint:disable no-mergeable-selectors no-important
@import '~@tko/src/styles/variables/variables';

//  *
//  *
//    Team Logo
//  *
//  *

//  Variables (if any)
//  ----------

// $logo-sizes: (
//     'default',
//     'list',
//     'grid',
//     'large',
//     'small'
// );

$logo-sizes: (
    large: 120px,
    default: 60px,
    list-larger: 36px,
    list: 32px,
    list-smaller: 28px,
    grid: 40px,
    small: 24px,
    smaller: 20px,
    tiny: 18px
);

$margin-sizes: (
    small: $margin--small,
    small-right: $margin--small,
    small-left: $margin--small,
    medium: $margin--medium,
    medium-right: $margin--medium,
    medium-left: $margin--medium,
    large: $margin--large,
    large-right: $margin--large,
    large-left: $margin--large,
    default: 0px
);

$logo-font-sizes: (
    large: 48px,
    default: 28px,
    list-larger: 16px,
    list: 12px,
    list-smaller: 12px,
    grid: 18px,
    small: 12px,
    smaller: 10px,
    tiny: 8px
);

$u-team-logo--size--default: map-get($logo-sizes, default);
$u-team-logo--font-size--default: map-get($logo-font-sizes, default);

//
//  Structure
//  ----------

.u-team-logo {
    display: flex;
    border-radius: 50%;
    flex: 0 0 $u-team-logo--size--default;
    font-size: $u-team-logo--font-size--default;
}


//
//  Elements
//  ----------

.u-team-logo {
    &__icon,
    &__img {
        border-radius: inherit;
        font-size: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        border: 1px solid $white;
        width: $u-team-logo--size--default;
        height: $u-team-logo--size--default;
        color: $white !important;
        flex: 0 0 auto;
        margin-left: 0;
    }
}

// Modifiers
//  ----------

.u-team-logo {
    $self: '.u-team-logo';

    &--white {
        background-color: $white;
        border-color: $color--text-placeholder;

        #{$self}__img,
        #{$self}__icon {
            color: $color--text-alt !important;
        }
    }
}

@each $size, $value in $logo-sizes {
    .u-team-logo--#{$size} {
        flex: 0 0 map-get($logo-sizes, $size);
        font-size: map-get($logo-font-sizes, $size);
        width: map-get($logo-sizes, $size);
        height: map-get($logo-sizes, $size);

        .u-team-logo__img,
        .u-team-logo__icon {
            width: inherit;
            height: inherit;
        }
    }
}

@each $size, $value in $margin-sizes {
    .u-team-logo--spacing-#{$size} {

        @if str-index($size, "right") {
            margin-right: $value;
        } @else if str-index($size, "left") {
            margin-left: $value;
        } @else {
            margin: $value;
        }

    }
}

// Export sizes for use in Vue props
// it lets us keep the sizes in the styles without having to duplicate
// it in the JavaScript
:export {
    @each $size, $value in $logo-sizes {
        #{$size}: $value;
    }
}
