






















.toggle-subtasks {
  margin-right: 8px;
  border: none;
  padding: 0;
  background: transparent;
  width: 16px;
  min-width: 16px;
  height: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;

  &.visible {
    visibility: visible;
  }
}
