













































































.dependency-popover-wrapper .w-popover__inner {
  width: 320px;
}
