@import '~@tko/src/styles/variables/_variables';

.btn-icon {
  background-color: transparent;
  border-radius: 100%;
  padding: 0;
  width: 28px;
  min-width: 28px;
  height: 28px;
  min-height: 28px;
  border: 0;
  outline: none;
  justify-content: center;
  transition-property: background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;

  &:hover {
    background-color: #f5f7fa; // Use 'Neutral 10' design token when available
  }

  &:active,
  &:focus {
    background-color: #e1e6ee; // Use 'Neutral 20' design token when available
  }

  svg {
    width: 14px;
    height: 14px;
  }
}

.classic-mode {
  background-color: transparent;
  padding: 0 1.07692em;
  min-width: 32px;
  height: 32px;
  border: 1px solid #eef1f6;
  line-height: 1;
  outline: none;
  justify-content: center;
  transition-property: background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  margin-left: 4px;
  margin-right: 4px;

  &:hover {
    background-color: #f5f7fa; // Use 'Neutral 10' design token when available
    border: 1px solid #f5f7fa;
  }

  &:active,
  &:focus {
    background-color: #e1e6ee; // Use 'Neutral 20' design token when available
    border: 1px solid #e1e6ee;
  }

  &:focus {
    outline: 5px auto #5e9ed6;
    outline: 5px auto -webkit-focus-ring-color;
  }

  svg {
    width: 14px;
    height: 14px;
  }
}
