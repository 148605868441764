






















.subtasks-count {
  color: #0b0e1f;
  border: 1px solid #e1e6ee;
  border-radius: 20px;
  min-height: 28px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__number {
    margin-left: 4px;
  }
}
