//
// IMPORTANT THESE COLORS ARE ALSO DEFINED IN CONSTANTS.COFFEE UNDER UICOLORS AND SHOULD ALWAYS MATCH
//
$semantic-colors: (
    white: #fff,
    late: #ff6461,
    highrisk: #ff6461,
    highpriority: #ff6461,
    deleted: #ff6461,
    mediumrisk: #ff9057,
    mediumpriority: #FFC300,
    lowrisk: #febe6f,
    lowpriority: #52c175,
    upcoming: #febe6f,
    pending: #febe6f,
    edited: #febe6f,
    today: #6cb3ff,
    active: #6cb3ff,
    completed: #138a5a,
    completedpercent: #138a5a,
    added: #4fc696,
    open: #4fc696,
    started: #4fc696,
    current: #6cb3ff,
    due: #4fc696,
    all: #b1bac5,
    nopriority: #b1bac5,
    nodate: #b1bac5,
    billable: #6076da,
    nonbillable: #b1bac5,
    billed: #138a5a,
    unbilled: #febe6f,
    inwork: #6cb3ff,
    read: #eee,
    unread: #3b93f7,
    new: #3b93f7,
    project: #3b93f7,
    archived: #b1bac5,
    closed: #b1bac5,
    starred: #febe6f,
    like: #4ba1ff,
    activity: #374674,
    activities: #374674,
    task: #374674,
    tasks: #374674,
    todo_items: #374674,
    tasklist: #374674,
    tasklists: #374674,
    time: #6e1cc6,
    timelog: #6e1cc6,
    timelogs: #6e1cc6,
    milestone: #895ef7,
    milestones: #895ef7,
    file: #85bd79,
    fileversion: #85bd79,
    files: #85bd79,
    filesversions: #85bd79,
    attachment: #85bd79,
    attachments: #85bd79,
    link: #ffaf4f,
    links: #ffaf4f,
    notebook: #ffa1b8,
    notebooks: #ffa1b8,
    risk: #ed581a,
    risks: #ed581a,
    event: #1cb986,
    events: #1cb986,
    calendarevent: #1cb986,
    calendar: #1cb986,
    message: #de5094,
    messages: #de5094,
    reply: #de5094,
    billing: #a981d6,
    comment: #1e9d4f,
    comments: #1e9d4f,
    page: #5a56fa,
    person: #999,
    board: #374674,
    account: #79E3AA,
    collaborator: #895EF7,
    contact: #4DA2F8,
    client: #1FCBDC,
    mail-active: #7cd79c,
    mail-inactive: #f19a92,
    is-observing: #8f8aee,
    owner: #5aaf29,
    site-owner: #2196f3,
    admin: #3dc25c,
    project-admin: #f39c12,
    invited: #fec974,
    info: #3b93f7,
    positive: #4fc696,
    negative: #ff6461,
    healthnone: #cccccc,
    healthgreen: #8bc34a,
    healthamber: #ffc107,
    healthred: #f44336,
    blankslate: #d6dadd,
    inherit: inherit
);

@each $name, $color in $semantic-colors {
    .h-text-color--#{$name} {
        color: $color !important;
    }

    .h-background-color--#{$name} {
        background-color: $color !important;
    }

    .h-icon-color--#{$name} {
        color: $color !important;
    }
}

//Backup
//From Project Activity Variables
// Color plate
$typeColor-taskAdded: #f39086;
$typeColor-taskComplete: #69d282;
$typeColor-tasklist: #6babed;
$typeColor-milestone: #c9a07e;
$typeColor-message: #8789eb;
$typeColor-comment: #98cd71;
$typeColor-sharedLink: #edbc75;
$typeColor-addedFile: #72cfd5;
$typeColor-invoice: #41c793;
$typeColor-notebook: #e18cac;
$typeColor-project: #65ba5a;

$tag-colors: (
    d84640: "#d84640",
    f78234: "#f78234",
    f4bd38: "#f4bd38",
    b1da34: "#b1da34",
    53c944: "#53c944",
    37ced0: "#37ced0",
    2f8de4: "#2f8de4",
    9b7cdb: "#9b7cdb",
    f47fbe: "#f47fbe",
    a6a6a6: "#a6a6a6",
    4d4d4d: "#4d4d4d",
    e6957: "#9e6957"//scss didn't like full var name partical works fine
);

:export {
    @each $name, $color in $semantic-colors {
        #{$name}: $color;
    }
}
