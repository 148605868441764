




















































.w-checkbox {
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
  font-size: 16px;

  &__text {
    margin-left: 8px;
    user-select: none;
  }
}

.w-checkbox__label {
  font-size: 14px;
  color: black;
}

.no-label {
  margin: 0px; // override default label margin
}
