













































.custom-field-date {
  &.tasks-table {
    height: 48px;
  }
}
