



































































@import '~@tko/src/styles/variables/variables';

.progress-popover {
  padding: 0;
  p {
    font-size: 14px;
    margin-bottom: 12px;

    &.has-value {
      span {
        color: $indigo-30;
        font-weight: 600;
      }
    }
  }
}
