
























$plus-sign-bg: #f5f7fa;
$hover-color: #4461d7;

.add-btn {
  padding: 0;
  outline: none;
  background-color: transparent;
  border: none;
  display: inline-flex;
  align-items: center;

  &.active:not(.disabled) {
    color: $hover-color;
    .plus-sign {
      svg {
        fill: $hover-color;
      }
    }
  }

  &:hover:not(.disabled) {
    color: $hover-color;

    .plus-sign {
      svg {
        fill: $hover-color;
      }
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: all;
  }
}

.plus-sign {
  display: inline-flex;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: $plus-sign-bg;
  position: relative;
  margin-right: 8px;
  justify-content: center;
  align-items: center;
}
