
















































































































































































.thumbnails-grid {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 8px;
}

.remaining-images-button {
  appearance: none;
  background-color: #eef1f5;
  border: none;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  border-radius: 4px;
}

.upload-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 82px;
  height: 62px;
  appearance: none;
  background-color: #fff;
  border: 1px dashed #4461d7;
  border-radius: 4px;
  &:hover {
    background-color: #eaecf4;
  }
}
