



















































.task-item-name__tooltip {
  max-width: 300px;
}
