









































































































































































































































































































.date-range__popover {
  .date-picker-wrapper {
    .rd-container {
      border: none;
    }
  }
}
