


















































































.logged-time {
  display: flex;
  flex-direction: column;

  &__row {
    display: flex;
    border: 1px solid #f5f7fa;
    border-radius: 12px;
    padding: 10px 16px 8px 16px;
    margin-bottom: 8px;

    &:hover {
      background-color: #f5f7fa;
      border-color: #6a84ed;
    }
  }

  &__content {
    padding-left: 8px;
    flex-grow: 2;
    display: flex;
    flex-direction: column;
  }

  &__user {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #0b0e1f;
    margin-bottom: 8px;
  }

  &__timecol {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__time {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    text-align: right;
    color: #0b0e1f;
    margin-bottom: 4px;
  }
}
