

























































































@import '~@tko/src/styles/variables/variables';

.subtask-popover {
  padding: $padding--medium;
  color: $color--text-tinted-dark;

  &__parent-task {
    display: block;
    font-size: $font-size--smaller;
    color: $color--text-tinted-dark;
    padding-bottom: $margin--small;
    margin-bottom: $padding--small;
    border-bottom: 1px solid $neutral-60;
    text-decoration: underline;
  }

  &__key {
    font-size: $font-size--small;
    vertical-align: top;
    white-space: nowrap;
    color: $color--text-sub;
    padding-right: $padding--medium;
  }

  &__value {
    font-size: $font-size--small;
    vertical-align: top;
    white-space: nowrap;
    color: $color--text-default;
  }
}
