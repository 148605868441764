































































































































































































































































































































































































$self: 'preview-container';

.preview-container {
  display: grid;
  grid-template-columns: 76px 1fr;
  background: #f7f7f7;
  height: 62px;
  border-radius: 4px;
  overflow: hidden;

  &:hover {
    .quick-actions-cont {
      opacity: 1;
      visibility: visible;
    }

    .file-name {
      margin-right: 20px;
    }

    .file-menu-button {
      opacity: 1;
    }
  }
  .quick-actions-cont {
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }
  .quick-action-button {
    appearance: none;
    background: #0b0e1f;
    border: none;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
}

.preview-file {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #e7ebf1;
  overflow: hidden;
}

.image-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  .image-el {
    width: 76px;
    height: 62px;
    object-fit: cover;
    object-position: center;
    box-shadow: none !important;
  }
}

.file-loading-icon {
  position: absolute;
  color: red;
}

.file-error {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    color: #6d6f80;
    font-size: 10px;
    margin-top: 5px;
  }
}

.file-contents {
  position: relative;
  padding: 8px;
  overflow: hidden;
  .file-menu {
    position: absolute;
    top: 0;
    right: 0;
  }
  .file-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    color: #0b0e1f;
    width: auto;
  }
  .file-version {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #646d7d;
    border-radius: 9px;
    height: 18px;
    min-width: 18px;
    color: #fff;
    font-size: 10px;
    font-weight: 600;
    padding: 0 3px;
    // margin-right: 4px;
  }
  .file-ext {
    color: #646d7d;
    font-size: 12px;
  }
}

.file-menu-button {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 8px;
  opacity: 0;
}

.file-menu-item {
  ::v-deep a {
    padding: 10px 16px;
  }
}

.flex {
  display: flex;
  &.center {
    justify-content: center;
  }

  &.middle {
    align-items: center;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.start {
    justify-content: flex-start;
  }
}

.mr-5 {
  margin-right: 5px;
}
.ml-8 {
  margin-left: 8px;
}
.mt-5 {
  margin-top: 5px;
}
