






































































































































.reminder-container {
  position: fixed;
  bottom: 24px;
  right: 72px;
  z-index: 9999;
  padding: 5px;
  display: flex;
  flex-direction: column;
}
.button-container {
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 1;
  padding: 5px;
  display: flex;
  flex-direction: row-reverse;
}
