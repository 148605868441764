






































.item-picker-popover {
  width: 200px;
  z-index: 15001;
  &__wrapper {
    border-radius: 8px;
    background-color: white;
    width: 280px;
    padding: 0 10px;
  }
}
