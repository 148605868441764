


















































































































































































































































































































































































































































































@import '@teamwork/ui-design-tokens/dist/web/tokens.scss';
@import '@tko/src/styles/variables/colors/_branding.colors.scss';
@import '@tko/src/styles/variables/colors/_background.colors.scss';

.TableView {
  color: $token-ui-text-01;

  overflow: scroll;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  &:hover {
    scrollbar-color: rgba(66, 82, 110, 0.36) rgba(255, 255, 255, 0);
    &::-webkit-scrollbar-thumb {
      background-color: rgba(66, 82, 110, 0.36);
    }
  }
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 6px;
  }

  &__content {
    width: max-content;
    min-width: 100%;
  }

  &__header {
    display: flex;
    position: sticky;
    top: 0;
    background: $token-ui-white;
    border-bottom: $token-size-px solid $neutral-50;
    color: #646d7d;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 500;
    font-size: 10px;
    z-index: 100;
    &--shadow {
      box-shadow: 0 8px 20px rgba(11, 14, 31, 0.06);
      border-bottom: 1px solid #f5f7fa;
    }
    &--dragging {
      width: min-content;
    }
  }

  &__cells {
    display: flex;
    flex: 1 1 auto;
    &--dragging {
      width: min-content;
    }
  }

  &__cell {
    position: sticky;
    display: flex;
    align-items: center;
    background: $token-ui-white;
    flex: 1 1 auto;
    box-sizing: border-box;
    white-space: nowrap;
    padding: 0 $token-size-4;
    line-height: 40px;

    &:first-child {
      padding-left: $token-size-0;
    }
    &:last-child {
      padding-right: $token-size-8;
    }
    &:hover > .TableView__cell-buttons {
      visibility: visible;
    }
    &:hover .TableView__cell-sort-arrow-icon {
      visibility: visible;
    }
    &--draggable {
      cursor: pointer;

      &.sortable-chosen,
      &.sortable-ghost {
        background-color: #e5e5e5;
      }
      &.sortable-drag {
        color: inherit;
        background-color: $token-ui-white;
      }
    }
    &--borders {
      border-right: $token-size-px solid $neutral-50;
    }
    &--dragging {
      &.sortable-ghost {
        cursor: grabbing;
        & > .TableView__cell-name {
          cursor: grabbing;
          color: #e5e5e5;
        }
      }
    }
    &--center {
      display: flex;
      justify-content: center;
      align-items: center;
      & > .TableView__cell-name {
        margin-left: 15px;
        justify-self: center;
      }
    }
    ::v-deep .v-resizable:hover {
      background-color: $indigo-30;
    }
  }

  &__cell-name {
    overflow: hidden;
    text-overflow: ellipsis;
    &--sortable {
      cursor: pointer;
    }
    &--sorting {
      color: #3c55bd;
    }
  }

  &__cell-sort-arrow {
    cursor: pointer;
  }

  &__cell-sort-arrow-icon {
    transition: transform 0.3s ease;
    margin: 0 $token-size-2;
    visibility: hidden;

    &--ascending {
      transform: rotate(180deg);
    }
    &--visible {
      visibility: visible;
    }
  }

  &__cell-buttons {
    visibility: hidden;
    margin-left: auto;
    line-height: 0;
    &--open {
      visibility: visible;
    }
    &-center {
      margin-left: 0;
    }
  }

  &__cell-buttons-content-button {
    padding: 10px 12px;
    width: min-content;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    border: none;
    white-space: nowrap;
    width: 100%;

    --radius-value: 8px;
    &:first-child {
      border-top-left-radius: var(--radius-value);
      border-top-right-radius: var(--radius-value);
    }
    &:last-child {
      border-bottom-left-radius: var(--radius-value);
      border-bottom-right-radius: var(--radius-value);
    }
    &:hover {
      background-color: #f5f7fa;
    }
  }

  &__cell-buttons-trigger-button {
    width: 16px;
    height: 16px;
    padding: 0;
    background: transparent;
    border: 1px solid #e7ebf1;
    border-radius: 4px;
  }

  &__cell-buttons-trigger-icon {
    width: 8px;
    height: 8px;
  }

  &__column-picker {
    position: sticky;
    right: 0;
    z-index: 1;
  }

  &__column-picker-component {
    position: absolute;
    top: 8px;
    right: 0;
  }
}
