



































$neutral-60: #0b0e1f;

.createdBy {
  display: flex;
  align-items: center;

  &__tooltip {
    cursor: pointer;
  }

  &__img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 8px;
    pointer-events: none;
  }

  &__time {
    font-size: 14px;
    color: $neutral-60;
  }
}
