


























































































.task-tags {
  display: flex;
  align-items: center;
}

.table-cell-tags {
  padding-left: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  overflow: overlay;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 6px;
  }

  &:not(:hover) {
    .table-cell-tags__picker--inactive {
      display: none;
    }
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: rgba(66, 82, 110, 0.36);
    }
  }

  .chevron::before {
    top: calc(50% - 10px);
    border: 0;
    transition: top ease 0.1s;
    color: inherit;
  }
}
