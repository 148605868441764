



















































@import '~@tko/src/styles/variables/variables';

.progress-slider {
  position: relative;
  width: 200px;
  height: 8px;
  background: rgba(68, 97, 215, 0.12);
  outline: none;
  border-radius: 50px;
  z-index: 2;

  .progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 50px;
    background: $indigo-30;
    z-index: -1;
  }

  input {
    -webkit-appearance: none;
    height: 8px;
    background: transparent;
  }

  input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: $indigo-30;
    cursor: pointer;
  }

  input:disabled::-webkit-slider-thumb {
    cursor: not-allowed;
  }

  input::-moz-range-thumb {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: $indigo-30;
    cursor: pointer;
  }

  input:disabled::-moz-range-thumb {
    cursor: not-allowed;
  }

  input::-moz-range-progress {
    background: $indigo-30;
  }
}
