






























@import '~@tko/src/styles/variables/variables';
.key-border {
  border: 1px solid $neutral-30;
}
