




































































































/* Had no choice but to access the trigger class here */
::v-deep .trigger {
  width: 100%;
  height: 100%;
  display: flex !important;
  width: 100%;
  align-items: center;
  justify-content: center;
}
