@import '~@tko/src/styles/variables/variables';
@import '~styles/variables/z-index';

$modal-dialog--max-height: 90vh;
$modal-dialog--min-width: 450px;
$modal-dialog--padding: 20px;

.w-modal-dialog {
    display: flex;
    position: relative;
    flex-direction: column;
    max-height: $modal-dialog--max-height;
    min-width: $modal-dialog--min-width;
    margin: auto 0;
    background: $modal-content--background-color;
    border-radius: $border-radius--default;
    box-shadow: $modal-content--box-shadow;
    transition: opacity 0.1s, transform 0.05s;
    overflow: auto;

    &__header {
        // structure modification
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        // reset styles
        border-radius: $border-radius--default $border-radius--default 0 0;
        padding: 24px $modal-dialog--padding;
        background-color: $modal-content--background-color;
        z-index: 2;
        transition: box-shadow 0.2s ease-in-out;
        
        &.scrolled {
            box-shadow: 0px 4px 12px rgba(11, 14, 31, 0.12);
        }
        
        &.bordered {
            border-bottom: 1px solid $modal-header--border-color;
        }
    }

    &__title {
        margin: 0;
        flex: 1 0 auto;
        color: #0B0E1F;
        font-size: 20px;
        font-weight: $font-weight--semibold;
        max-width: calc(100% - 20px);
    }

    &__close {
        display: flex;
        align-items: center;
        margin-left: $margin--medium;
        cursor: pointer;
        pointer-events: all;
        border: 0;
        background-color: transparent;
        -webkit-appearance: none;

        &:focus {
            outline: none;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        position: relative;
        padding: ($modal-inner--padding - 5) $modal-inner--padding
            $modal-inner--padding;
        overflow-y: auto;
        flex: 1 1 auto;
    }

    &__footer {
        display: flex;
        background-color: $modal-content--background-color;
        padding: ($modal-dialog--padding - 5) $modal-dialog--padding;
        border-radius: 0 0 $border-radius--default $border-radius--default;
    }
}

// Fade transition
.slide-enter-active,
.slide-leave-active {
    transition: transform 0.15s ease-in-out;
}

.slide-enter,
.slide-leave-to {
    transform: translateY(-100%);
}

.slide-right-enter-active,
.slide-right-leave-active {
  transition: transform 0.15s ease-in-out;
}

.slide-right-enter,
.slide-right-leave-to {
  transform: translateX(100%);
}

