
































$neutral-60: #0b0e1f;
.picker-label {
  color: $neutral-60;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  margin-left: 12px;
}
