















h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  padding-bottom: 6px;
  display: flex;
  align-items: center;
  color: #646d7d;
}
