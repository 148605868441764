




























































































































































































































.dependency-picker-popover-wrapper .w-popover__inner {
  width: 320px;
  padding: 16px 0 8px 0;
}
