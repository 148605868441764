


























































































































































































@import '~@tko/src/styles/variables/dimensions/_margins-paddings.dimensions';

// This is a temporary override to avoid harming global popovers
// This should be removed when the component is updated
.notifications__header-options-popover {
  flex: 0 0 auto;
}

.notifications__header-options-popover-content {
  .w-popover__inner {
    overflow: hidden;
  }

  ul {
    user-select: none;
    list-style-type: none;
    padding: 0;

    li {
      font-size: 14px;
      line-height: 24px;
      color: #0b0e1f; // Use 'Neutral 60' design token when available
      padding: 10px 14px;
      border-radius: 8px;
      transition-property: background-color, border-color, color, fill, stroke;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;
      position: relative;
      z-index: 1;

      &:hover {
        background-color: #f5f7fa; // Use 'Neutral 10' design token when available
      }

      &[disabled] {
        cursor: default;
        color: #c5cee0; // Use 'Neutral 30' design token when available

        &:hover {
          background-color: transparent;
        }

        svg {
          fill: #c5cee0; // Use 'Neutral 30' design token when available
        }
      }

      svg {
        fill: #646d7d; // Use 'Neutral 50' design token when available
        width: 16px;
      }
    }
  }
}
