































.primary {
  background-color: #ff61c7;
}
.secondary {
  background-color: #895ef7;
}
.dark {
  background-color: #0b0e1f;
}
