































.comments {
  list-style: none;
  padding: 0;

  li {
    display: flex;
    margin-bottom: 16px;
  }

  &__body {
    margin-left: 16px;

    &--header {
      font-size: 12px;
      line-height: 18px;
      color: #6d6f80;
    }

    &--comment {
      font-size: 14px;
      line-height: 18px;
      color: #0b0e1f;
    }
  }
}
