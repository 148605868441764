











































@import '~@tko/src/styles/variables/variables';

.w-header-helper {
  &__trigger {
    display: inline;

    .w-svg-sprite {
      vertical-align: middle;
    }
  }

  &__popover {
    padding: $padding--small $padding--medium;
    color: white;
    background: black;
    border-radius: $border-radius--default;
    max-width: 225px;
    box-shadow: 0px 0px 12px rgba(11, 14, 31, 0.08);
    font-weight: normal;
    font-size: $font-size--small;
    line-height: 20px;
  }
}
