





















.table-cell-tick {
  width: 100%;
  padding: 10px;
  text-align: center;
}
