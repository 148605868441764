































































































.template-offset-day-picker {
  &__header {
    padding: 4px;
  }

  &__input {
    border: 1px solid #e1e1e1;
    padding: 4px;
  }

  &__list {
    max-height: 200px;
    overflow: scroll;
    padding: 0;
  }

  &__option {
    list-style: none;
    padding: 6px 10px;
    color: #555;

    &:hover:not(.is-disabled) {
      cursor: pointer;
      color: white;
      background-color: #5897fb;
    }

    &.is-selected {
      background-color: #e1e1e1;
    }
  }
}
