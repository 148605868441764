





























































































































































































































































@import '~@tko/src/styles/variables/variables';

$light-gray: #6d6f80;
$darker-indigo: #3c55bd;
$font-size--base: 14px;

.table-cell-task-comments {
  margin-right: 12px;
  color: $light-gray;
  display: flex;
  align-items: center;
  height: 28px;

  .comment-btn {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
    padding-right: 0px;
    background: none;
    border: none;

    &__text {
      font-size: $font-size--h4;
      color: $color--text-tinted-dark;
      margin-left: 3px;
      font-weight: $font-weight--normal;
      text-align: center;
    }
  }

  .u-common-icon {
    font-size: $font-size--btn;
  }
}

.comment-popover-outer {
  .w-popover__inner {
    box-shadow: 0px 4px 12px rgba(11, 14, 31, 0.16);
  }

  .comment-loader {
    min-height: 100px;
  }
}

.comment-popover {
  display: flex;
  justify-content: space-between;
  width: 392px;
  min-height: 80px;
  padding: $padding--large;
  font-size: $font-size--base;

  .btn-reset {
    background: none;
    border: none;
    padding: 0;
  }

  .spacer {
    flex: 1;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: $margin--medium;
  }

  &__avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 5px;
  }

  &__name {
    color: $color--text-tinted-dark;
    font-weight: $font-weight--semibold;
    margin-right: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 133px;
  }

  &__date {
    color: $light-gray;
  }

  &__body {
    color: $color--text-tinted-dark;
    max-height: 80px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    font-size: $font-size--base;
    line-height: 20px;
    padding-left: $padding--xx-large;
    padding-right: $padding--large;
    margin-bottom: $margin--large;

    img {
      display: none;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    padding-left: $padding--xx-large;
  }

  &__attachments {
    font-size: $font-size--base;
    color: $light-gray;
  }

  &__action {
    font-size: $font-size--base;
    margin-right: $margin--medium;

    &:last-of-type {
      margin-right: 0;
    }

    &.text-light-gray {
      color: $color--text-tinted-dark;
      font-weight: $font-weight--normal;

      &:hover,
      &:focus {
        color: $color--text-tinted-dark;
      }
    }
  }
}
