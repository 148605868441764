





























































































































































/* Track */
input {
  -webkit-appearance: none;
}

input:disabled {
  cursor: not-allowed;
}

/* Knob */
input::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 12px;
  height: 12px;
  border-radius: 9999px;
  cursor: pointer;
  background: var(--token-c-slider-color-knob);
}

input:disabled::-webkit-slider-thumb {
  background: var(--token-c-slider-color-knob-disabled);
  cursor: not-allowed;
}

input:not(:disabled):hover::-webkit-slider-thumb {
  box-shadow: var(--token-c-slider-color-shadow);
}

input:not(:disabled):focus-within::-webkit-slider-thumb {
  box-shadow: var(--token-c-slider-color-shadow-strong);
}

input:not(:disabled):active::-webkit-slider-thumb {
  box-shadow: var(--token-c-slider-color-shadow-strongest);
}

input::-moz-range-thumb {
  width: 12px;
  height: 12px;
  border-radius: 9999px;
  cursor: pointer;
  background: var(--token-c-slider-color-knob);
}

input:disabled::-moz-range-thumb {
  background: var(--token-c-slider-color-knob-disabled);
  cursor: not-allowed;
}

input:not(:disabled):hover::-moz-range-thumb {
  box-shadow: var(--token-c-slider-color-shadow);
}

input:not(:disabled):focus-within::-moz-range-thumb {
  box-shadow: var(--token-c-slider-color-shadow-strong);
}

input:not(:disabled):active::-moz-range-thumb {
  box-shadow: var(--token-c-slider-color-shadow-strongest);
}

input::-ms-thumb {
  width: 12px;
  height: 12px;
  border-radius: 9999px;
  cursor: pointer;
  background: var(--token-c-slider-color-knob);
}

input:disabled::-ms-thumb {
  background: var(--token-c-slider-color-knob-disabled);
  cursor: not-allowed;
}

input:not(:disabled):hover::-ms-thumb {
  box-shadow: var(--token-c-slider-color-shadow);
}

input:not(:disabled):focus-within::-ms-thumb {
  box-shadow: var(--token-c-slider-color-shadow-strong);
}

input:not(:disabled):active::-moz-range-thumb {
  box-shadow: var(--token-c-slider-color-shadow-strongest);
}
