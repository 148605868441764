





































$sizeList: (
  default: 28px,
  small: 20px,
  medium: 32px,
  big: 44px,
);

$iconSizeList: (
  default: 12px,
  small: 11px,
  medium: 14px,
  big: 22px,
);

$sizes: default, small, medium, big;
$btn-color: #e1e6ee;
$icon-fill: #6d6f80;
$icon-active-fill: #4461d7;
$icon-disabled-fill: #d8e0ff;

.round-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $btn-color;
  color: $btn-color;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  position: relative;

  &__badge {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    position: absolute;
    bottom: -7px;
    right: -4px;
  }

  svg {
    fill: $icon-fill;
  }

  &.disabled {
    border-color: $icon-disabled-fill;
    cursor: not-allowed;

    svg {
      fill: $icon-disabled-fill;
    }
  }

  &.active {
    border: 1px solid $icon-active-fill;
    cursor: pointer;

    svg {
      fill: $icon-active-fill;
    }

    .round-btn__badge {
      background-color: $icon-active-fill;
    }
  }

  &.primary {
    background-color: $icon-active-fill;
    border-color: $icon-active-fill;

    svg {
      fill: #fff;
    }
  }

  &:hover:not(.disabled) {
    border-color: $icon-active-fill;

    svg {
      fill: $icon-active-fill;
    }
  }

  /* SIZES */
  @each $size in $sizes {
    &.size-#{$size} {
      width: map-get($sizeList, $size);
      height: map-get($sizeList, $size);
      border-radius: map-get($sizeList, $size) / 2;

      svg {
        width: map-get($iconSizeList, $size);
        height: map-get($iconSizeList, $size);
      }
    }
  }
}
