


















button {
  background: transparent;
  margin: 0;
  color: #4461d7;
  font-size: 14px;
  line-height: 20px;
  border: none;
  padding: 0;
}

.button__underline {
  &:hover {
    text-decoration: underline;
  }
}
