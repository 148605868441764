//  Fonts
//  ----------
$open-sans: "Open Sans";
$inter: "Inter";
$font-nunito: 'Inter', sans-serif;
$font-family-sans-serif: "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family--fallback: $font-family-sans-serif;
$font-family--initial: $font-family--fallback;
$font-family: $inter, $open-sans, $font-family--fallback;

//## Font Sizes
$font-size--h1: 24px;
$font-size--h2: 20px;
$font-size--h3: 16px;
$font-size--h4: 13px;
$font-size--h5: 12px;
$font-size--h6: 11px;

$font-size--smallest: 10px;
$font-size--tiny: $font-size--smallest;
$font-size--smaller: 11px;
$font-size--small: 12px;
$font-size--default: 13px;
$font-size--btn: 14px;
$font-size--medium: 15px;
$font-size--large: 18px;
$font-size--x-large: 24px;
$font-size--xx-large: 28px;

//Line Heights
$line-height--small: 1.5;
$line-height--default: 1.428571429;
$line-height--large: 1.8;
$line-height--computed: floor(
    ($font-size--default * $line-height--default)
); // ~20px

//** By default, this inherits from the `<body>`.
$font-weight--heading: normal;
$line-height--heading: $line-height--default;

// Font Weight
// ----------

$font-weight--light: 300;
$font-weight--normal: 400;
$font-weight--semibold: 600;
$font-weight--bold: 700;

/*
                     !!! IMPORTANT !!!

                     DON'T PUT ANY STYLES NOT RELATED TO TYPOGRAPHY HERE
                     ONLY FONTS, LINE-HEIGHTS, FONT-FAMILIES, ETC. PLEASE

                     !!! IMPORTANT !!!
*/
