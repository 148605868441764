



































@import '~@tko/src/styles/variables/variables';

.log-time-button {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $neutral-40;
  background: none;
  height: 100%;
  width: 28px;

  .icon {
    fill: #646d7d;
  }

  &:hover {
    border: 1px solid #4461d7;
    .icon {
      fill: #4461d7;
    }
  }

  &.is-active {
    border: 1px solid #4461d7;
    padding-left: 5px;
    .icon {
      fill: #4461d7;
    }
  }

  &.is-pause {
    border: 1px solid #e12d42;
    padding-left: 4px;
    .icon {
      fill: #e12d42;
    }
  }

  &.is-resume {
    border: 1px solid #4461d7;
    padding-left: 5px;
    .icon {
      fill: #4461d7;
    }
  }

  &.is-table-view {
    border: 1px solid #4461d7;
    padding-left: 5px;
    .icon {
      fill: #4461d7;
      font-size: 12px;
    }
    &:hover {
      background: #f2f4fc;
    }
  }
}
.direction-left {
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  border-right: 0.5px solid $neutral-40;
  padding-right: 5px;
}

.direction-right {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  border-left: 0.5px solid $neutral-40;
}
