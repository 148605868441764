
































.table-cell-task-quick-view {
  display: none;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  margin-right: 12px;
  a {
    cursor: pointer;
    color: #4461d7;
    font-size: 13px;
    font-weight: 400;
    &:hover {
      text-decoration: underline;
    }
  }
}
