








































































































































































































































































































































.time-logs {
  margin-bottom: 8px;

  table {
    width: 100%;
    margin: 10px 0;

    th {
      font-size: 14px;
      line-height: 24px;
      color: #6d6f80;
      padding: 8px;
    }

    td {
      padding: 12px;
      box-shadow: inset 0 1px 0 0 #f5f7fa;

      &:not(:first-child) {
        box-shadow: inset 1px 1px 0 0 #f5f7fa;
      }

      &:last-child {
        box-shadow: inset 0px 1px 0 0 #f5f7fa;
      }
    }

    th,
    td {
      &.centered {
        text-align: center;
      }

      &:last-of-type {
        padding: 0;
      }
    }
  }

  &__description {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90px;
  }

  &__time {
    width: 50px;
  }

  &__assignee {
    width: 28px;
    height: 28px;
    border-radius: 14px;
    font-size: 13px;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
  }

  .context-menu-button {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    border: 1px solid #f5f7fa;
    background: transparent;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
}
