




















































































.animate-spin {
  animation-duration: 5s;
}
