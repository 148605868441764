




























.item-picker-no-result {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  &__message {
    margin-top: 8px;
    color: #646d7d;
    font-size: 14px;
  }
}
