































































.image-slot img,
.image-slot svg {
  max-height: 180px;
  max-width: 240px;
}
.image-slot img {
  object-fit: contain;
}
