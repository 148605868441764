$white: #fff;
$color-delete: #c20000;

//Branding
$color--brand-blue: #3b93f7;
$color--brand-blue-dark: darken($color--brand-blue, 20%); //??
$color--brand-red: #ff3454;
$color--brand-green: #65d656;
$color--brand-green-dark: darken($color--brand-green, 20%);
$color--brand-navy: #3b4b69;
$color--impersonating: #b94a48;

$color--light-blue: #63a9f9;

$branding-slate: #1d1c39;
$branding-slate-light: #373753;

$background-color--header: #3c5574;
$background-color--body: $white;
$background-color--header-new: #1d1c39;
$background-color--header-bottom-new: #373753;

$color--heading-dark: #222;
$color--heading: #333;
$color--state-selected: #333;
$color--unread-indicator: #4890e2;

// Main Text colors
$color--text-default: #555;
$color--text-alt: #777; // USED IN NEW DASHBOARD - COLOR NEEDS REVIEW
$color--text-secondary: #888;
$color--text-secondary-alt: #999; // USED IN NEW DASHBOARD - COLOR NEEDS REVIEW
$color--icon-default: #888;
$color--text-placeholder: #bbb;
$color--text-sub: #999;
$color--text-subtle: #AAA;
$color--elements: $color--text-secondary;
$color--filter: #4461D7;
$color--link: #4461D7;
$color--link-hover: $color--light-blue;
$color--success: #34c48b;
$color--error: #ff6562;
$color--info: #f2825b;
$color--warning: #ffc486;
$color--danger: $color--error;
$color--today: #6cb3ff;
$color--late: #ff6461;
$color--upcoming: #febe6f;
$color--disabled: #ccc;
$color--text-tinted-dark: #0b0e1f;
$color--text-tinted-secondary: #D6D7DA;

$color--default-alert: #2196f3;
$color--error-alert: #f44336;
$color--card-expiry-alert: #9c27b0;
$color--billing-alert: #9c27b0;
$color--product-launch-alert: #eef1f6;
$color--privacy-alert: #d36e69;

$color--low-risk: #fbbd75;
$color--medium-risk: #fd905d;
$color--high-risk: #fc6565;

$color--metric-info: #9ca9b5;
$color--metric-unbilled: #febe6f;
$color--metric-billed: #138a5a;
$color--metric-billable: #6076da;
$color--metric-non-billable: #b1bac5;

// Backgrounds
$background-color--light: #fafafa;
$background-color--default: #F5F7FA;
$background-color--dark: #f5f5f5;

//Progress Color
$background-color--progress: #6bbbe8;

// Borders
$border-color--extralight: #f2f2f2;
$border-color--light: #eceff3;
$border-color--default: #EDF1F4;
$border-color--dark: #ddd;
$border-color--highlight: #4461D7;

$border-color--active: $color--success;

$color--link-action: $color--text-default;
$border-color--link-action: rgba(95, 110, 133, 0.2);
$border-color--link-action-hover: rgba(95, 110, 133, 1);

$color--link-gray: $color--text-default;
$color--link-gray-hover: $color--heading;

$color--btn-link-disabled: $color--text-placeholder;
$color--component-active-text: $color--text-default;

$background-color--selected: rgba(99, 169, 249, 0.1);

$color--state-success-text: $color--heading;
$background-color--state-success: $color--success;

$color--state-warning-text: $color--heading;
$background-color--state-warning: $color--warning;

$color--state-danger-text: $color--heading;
$background-color--state-danger: $color--error;

// Buttons
$color--disabled-button: #ddd;

// Star
$color--star-unselected: $color--text-placeholder;
$color--star-unselected-hover: $color--elements;
$color--star-selected: #ffbc77;

// Task Priority
$low-priority--color: desaturate($color--success, 0%);
$medium-priority--color: $color--warning;
$high-priority--color: $color--danger;

// Buttons
$color--btn-default: $color--text-default;
$background-color--btn-default: #fff;
$border-color--btn-default: $border-color--dark;

$color--btn-primary: $white;
$background-color--btn-primary: $color--brand-blue;
$border-color--btn-primary: $color--brand-blue;

$color--btn-info: #fff;
$background-color--btn-info: $color--info;
$border-color--btn-info: $color--info;

$color--btn-success: #fff;
$background-color--btn-success: $color--success;
$border-color--btn-success: $color--success;

$color--btn-warning: #fff;
$background-color--btn-warning: $color--warning;
$border-color--btn-warning: $color--warning;

$color--btn-danger: #fff;
$background-color--btn-danger: $color--error;
$border-color--btn-danger: $color--error;

// Global background color for active items (e.g., navs or dropdowns).
$component-active--background-color: $background-color--selected;

// Close
$close--color: $color--elements;

// Forms

//** `<input>` background color
$input--background-color: $white;
//** `<input disabled>` background color
$input-disabled--background-color: $background-color--dark;

//** Text color for `<input>`s
$input--color: $color--heading;
//** `<input>` border color
$input--border-color: $border-color--dark;

//** Border color for inputs on focus
$focus-input--border-color: $border-color--highlight;

//** Placeholder text color
$input-placeholder--color: $color--text-placeholder;

$legend--color: $color--heading;
$legend--border-color: #fff;

//** Background color for textual input addons
$input-group-addon--background-color: $background-color--default;
//** Border color for textual input addons
$input-group-addon--border-color: $border-color--dark;

//== Tables
//

//** Default background color used for all tables.
// $table--background-color: transparent;
// //** Background color used for `.table-striped`.
// $table-accent--background-color: $background-color--light;
// //** Background color used for `.table-hover`.
// $table-hover--background-color: $background-color--default;
// $table-active--background-color: $table-hover--background-color;

// //** Border color for table and cell borders.
// $table--border-color: $border-color--default;

$background-color--table-stripped: $background-color--light;

//== Type
//
//##

//** Abbreviations and acronyms border color
$abbr-border--color: $color--text-sub;
//** heading small color
$heading-small--color: $color--text-sub;
//** Blockquote small color
$blockquote-small--color: $color--text-sub;

//** Blockquote border color
$blockquote--border-color: $border-color--light;
//** Page header border color
$page-header--border-color: $border-color--light;

//** Horizontal line color.
$hr--border-color: $border-color--light;

//== Badges
// NEEDS DESIGN REVIEW
//##

//** Linked badge text color on hover
$badge-link-hover--color: $color--link;
$badge--background-color: #aaa;

//** Badge text color in active nav link
$badge-active--color: $color--link;
//** Badge background color in active nav link
$badge-active--background-color: #fff;

//== Thumbnails
//
//##

//** Thumbnail background color
$thumbnail--background-color: $background-color--body;
//** Thumbnail border color
$thumbnail--border-color: #ddd;

//** Custom text color for thumbnail captions
$thumbnail-caption--color: $color--text-default;

//== Labels
//
//##

//** Default label background color
$label-default--background-color: #eee;
//** Primary label background color
$label-primary--background-color: $label-default--background-color;
//** Brand label background color
$label-brand--background-color: $color--brand-blue;
//** Success label background color
$label-success--background-color: $color--success;
//** Info label background color
$label-info--background-color: $color--info;
//** Warning label background color
$label-warning--background-color: $color--error;
//** Danger label background color
$label-danger--background-color: $color--error;

//** Default label text color
$label--color: #fff;
//** Default text color of a linked label
$label-link-hover--color: #fff;

//== Navs
//
//##

//=== Shared nav styles
$nav-link-hover--background-color: $border-color--light;

$nav-disabled-link--color: $color--text-sub;
$nav-disabled-link-hover--color: $color--text-sub;

//== Tabs
$nav-tabs--border-color: $border-color--default;

$nav-tabs-link-hover--border-color: $border-color--light;

$nav-tabs-active-link-hover--background-color: $background-color--body;
$nav-tabs-active-link-hover--color: $color--text-default;
$nav-tabs-active-link-hover--border-color: $border-color--default;

$nav-tabs-justified-link--border-color: $border-color--default;
$nav-tabs-justified-active-link--border-color: $background-color--body;

//== Pills
$nav-pills-active-link-hover--background-color: $component-active--background-color;
$nav-pills-active-link-hover--color: $color--component-active-text;

//== Modals
//
//##

$modal-header--background-color: $background-color--default;

//** Background color of modal content area
$modal-content--background-color: #fff;
//** Modal content border color
$modal-content--border-color: $border-color--default;
//** Modal content box shadow
$modal-content--box-shadow: 0px 16px 40px 0px rgba(11, 14, 31, 0.16);

//** Modal backdrop background color
$modal-backdrop--background-color: #0B0E1F;
//** Modal backdrop opacity
$modal-backdrop--opacity: 0.2;
//** Modal header border color
$modal-header--border-color: #e1e6ee;

//** Modal footer border color
$modal-footer--border-color: $modal-header--border-color;

//== List group
//
//##

//** Background color on `.list-group-item`
$list-group--background-color: #fff;
//** `.list-group-item` border color
$list-group--border-color: $border-color--default;

//** Background color of single list items on hover
$list-group-hover--background-color: #f5f5f5;
//** Text color of active list items
$list-group-active--color: $color--component-active-text;
//** Background color of active list items
$list-group-active--background-color: $component-active--background-color;
//** Border color of active list elements
$list-group-active--border-color: $list-group-active--background-color;
//** Text color for content within active list items
$list-group-active-text--color: $color--heading;

//** Text color of disabled list items
$list-group-disabled--color: $color--text-placeholder;
//** Background color of disabled list items
$list-group-disabled--background-color: $background-color--dark;
//** Text color for content within disabled list items
$list-group-disabled-text--color: $list-group-disabled--color;

$list-group-link--color: $color--link;
$list-group-link-hover--color: $list-group-link--color;
$list-group-link-heading--color: $color--text-secondary;

//Backup
//From Project Activity Variables
// Color plate

$typeColor-taskAdded: #f39086;
$typeColor-taskComplete: #69d282;
$typeColor-tasklist: #6babed;
$typeColor-milestone: #c9a07e;
$typeColor-message: #8789eb;
$typeColor-comment: #98cd71;
$typeColor-sharedLink: #edbc75;
$typeColor-addedFile: #72cfd5;
$typeColor-invoice: #41c793;
$typeColor-notebook: #e18cac;
$typeColor-project: #65ba5a;


