





















































































































































































































@import '~@tko/src/styles/mixins/mixins';

.scrollbar {
  @include scrollbar();
}

.fade-enter-active,
.fade-leave-active {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
