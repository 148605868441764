


































































































































































































@import '~@tko/src/styles/variables/variables';

$font-size--base: 14px;
$input-height: 32px;
$error-color: #e02020;
$inputs-margin-bottom: 16px;

.timer-inputs {
  display: flex;

  flex-direction: column;
  &--inline {
    flex-direction: row;
  }

  &__inputs {
    display: flex;
    margin-bottom: $inputs-margin-bottom;
  }

  &__input-with-label {
    display: flex;
    align-items: center;
  }

  &__input {
    height: $input-height;
    font-size: $font-size--base;
    background-color: $neutral-20;
    margin-right: $margin--small;
    padding: 0 12px;
    font-weight: normal;
    border-radius: 4px;
    border: 1px solid transparent;
    transition: all 0.2s ease-in-out;
    width: 49px;
    text-align: right;

    &[type='number'] {
      appearance: none;
      -moz-appearance: textfield;
      &::-webkit-inner-spin-button,
      ::-webkit-outer-spin-button {
        -webkit-appearance: none;
      }
    }

    &.error {
      border: 1px solid $error-color;
    }

    &:focus {
      outline: none;
      border: 1px solid $indigo-30;
      &.error {
        border: 1px solid $error-color;
      }
    }
  }

  &__checkbox {
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: $inputs-margin-bottom;

    &--inline {
      margin-left: 8px;
    }
  }

  &__label {
    margin-bottom: 0;
    font-weight: 600;
    color: #0b0e1f;

    &--checkbox {
      font-size: 14px;
      font-weight: 400;
      color: black;
      user-select: none;
      margin-left: 8px;
    }
  }
}
