







































































































































































.dependencies-item {
  &__status {
    margin-right: 8px;
    margin-left: 24px;
  }
}
