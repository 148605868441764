
























































@import '@teamwork/ui-design-tokens/dist/web/tokens.scss';
@import '@tko/src/styles/variables/colors/_branding.colors.scss';

.TableViewRow {
  display: flex;
  background: $token-ui-white;

  &__cell {
    position: relative;
    background: inherit;
    display: flex;
    align-items: center;
    text-align: left;
    border-bottom: $token-size-px solid $neutral-50;

    &--no-bottom-border {
      border-bottom: 0;
    }

    &--sticky {
      position: sticky;
      z-index: 10 !important;
    }

    &--center {
      justify-content: center;
    }
  }

  &:hover {
    // TODO remove this style - it should be added by the client code as needed
    background: $neutral-10;
  }

  &__cell-placeholder {
    margin: auto 10px;
    width: 100%;
    height: 1em;
    border-radius: 0.5em;
    animation: placeholder-glow 0.8s linear infinite alternate;
  }
}

@keyframes placeholder-glow {
  from {
    background: #e5e6ee;
  }

  to {
    background: #e5e6ee66;
  }
}
