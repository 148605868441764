




























































































































.recent-project-list-item {
  content-visibility: auto;
}
