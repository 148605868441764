


























$color: #f5f7fa;

.collapse-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  &:after {
    content: '';
    height: 1px;
    background-color: $color;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    z-index: -1;
  }
}

button {
  background-color: $color;
  border: none;
  height: 24px;
  padding: 0 22px;
  border-radius: 12px;
}
