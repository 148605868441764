



















































































































































































































































@import '~@tko/src/styles/variables/variables';

$text-color: #0b0e1f;
$border-color: #c5cee0;
$completed-color: #4ecd97;
$blocked-color: #e64d4d;
$counter-color: #d8e0ff;
$counter-text-color: #364daa;
$secondary-text-color: #646d7d;
$hover-color: #f5f7fa;

$sizeList: (
  tiny: 18px,
  small: 20px,
  default: 24px,
  medium: 32px,
  big: 40px,
);
$sizeMargins: (
  tiny: 6px,
  small: 8px,
  default: 8px,
  medium: 15px,
  big: 20px,
);
$sizeFonts: (
  tiny: 8px,
  small: 10px,
  default: 12px,
  medium: 14px,
  big: 18px,
);

$sizes: tiny, small, default, medium, big;

.task-toggle-button {
  display: inline-flex;
  border: 1px solid $border-color;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: $text-color;

  svg {
    width: 12px;
    height: 12px;
    fill: #8f9bb3;
  }

  &:hover:not(.readonly) {
    svg {
      fill: $text-color;
    }
  }

  &.is-completed {
    background-color: $completed-color;
    border: 1px solid $completed-color;
    .w-svg-sprite {
      fill: $white;
    }
  }
  &.is-completed:hover {
    .w-svg-sprite {
      fill: $white;
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 1;
  }

  &.is-blocked {
    background-color: white !important;
    border-color: $blocked-color !important;
    .w-svg-sprite {
      fill: $blocked-color !important;
      display: inherit !important;
    }
  }

  &.cannot-complete {
    background-color: $neutral-80;
    border-color: $neutral-80;
    cursor: not-allowed;
    &:active {
      background-color: $neutral-80;
      border: none;
    }
    .w-svg-sprite {
      display: none;
    }
  }

  &.has-subtask {
    background-color: $counter-color;
    border-color: $counter-color;
    color: $counter-text-color;
    font-weight: 600;
  }

  &.in-progress {
    background-color: white;

    .w-svg-sprite {
      display: inline-block;
    }
  }

  &.readonly {
    cursor: default;
  }

  /* SIZES */
  @each $size in $sizes {
    &.size-#{$size} {
      width: map-get($sizeList, $size);
      height: map-get($sizeList, $size);
      border-radius: map-get($sizeList, $size) / 2;
      margin-right: map-get($sizeMargins, $size);
      font-size: map-get($sizeFonts, $size);
    }
  }
}

.check-icon {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  &:after,
  &:before {
    content: '';
    position: absolute;
    background-color: #8f9bb3;
    height: 1px;
  }
  &:before {
    width: 5px;
    transform: rotate(45deg);
    top: 7px;
    left: 0px;
  }
  &:after {
    width: 8px;
    transform: rotate(-45deg);
    top: 5px;
    left: 3px;
  }
}

.task-toggle-predecessors {
  padding: 16px 0 8px 0;
  max-width: 300px;
  font-size: 14px;

  p {
    margin: 0 16px;
    &:first-of-type {
      font-weight: 600;
      margin-bottom: 8px;
    }
    &:last-of-type {
      color: $secondary-text-color;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin-top: 8px;
    overflow-y: auto;
    max-height: 130px;
  }

  li {
    padding: 8px 16px;
    display: flex;
    align-items: center;
    &:not(.loading) {
      cursor: pointer;
    }
    .task-toggle-button {
      flex-shrink: 0;
    }
    a {
      flex-grow: 2;
      text-decoration: none;
      color: $text-color;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &:hover:not(.loading) {
      background-color: $hover-color;
    }
  }

  .task-skeleton {
    width: 180px;
    height: 20px;
  }
}
