























































































































@import '~@tko/src/styles/mixins/mixins';

.item-picker-content {
  margin-top: 16px;
  height: 100%;
  max-height: 295px;
  position: relative;
  overflow: scroll;
  @include scrollbar();

  &__footer {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
.loading_opacity {
  opacity: 0.3;
}
.spinner {
  margin: 0 auto;
}
