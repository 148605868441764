





























































































































































@import '~@tko/src/styles/variables/variables';

$font-size--base: 14px;

.table-cell-progress {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;

  &__empty-state {
    font-size: $font-size--base;
    position: absolute;
    left: 0;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
  }
}

.progress-popover {
  width: 100%;
  padding: 16px;

  .trigger {
    height: 35px;
    width: 100%;
  }

  .progressTableCell {
    margin: 0;
    height: 100%;
  }

  &__text {
    font-size: $font-size--base;
    margin-bottom: 18px;

    &.has-value {
      span {
        color: $indigo-30;
        font-weight: 600;
      }
    }
  }

  .slider {
    position: relative;
    width: 180px;
    height: 8px;
    background: rgba(68, 97, 215, 0.12);
    outline: none;
    border-radius: 50px;
    z-index: 2;

    &__progress {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      border-radius: 50px;
      background: $indigo-30;
      z-index: -1;
    }

    &__input {
      -webkit-appearance: none;
      height: 8px;
      background: transparent;
    }

    &__input::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: $indigo-30;
      cursor: pointer;
    }

    &__input:disabled::-webkit-slider-thumb {
      cursor: not-allowed;
    }

    &__input::-moz-range-thumb {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: $indigo-30;
      cursor: pointer;
    }

    &__input:disabled::-moz-range-thumb {
      cursor: not-allowed;
    }

    &__input::-moz-range-progress {
      background: $indigo-30;
    }
  }
}
