//== Modals
//
//##

$modal-header--background-color: $background-color--default;

//** Background color of modal content area
$modal-content--background-color: #fff;
//** Modal content border color
$modal-content--border-color: $border-color--default;
//** Modal content box shadow
$modal-content--box-shadow: 0px 16px 40px 0px rgba(11, 14, 31, 0.16);

//** Modal backdrop background color
$modal-backdrop--background-color: #0B0E1F;
//** Modal backdrop opacity
$modal-backdrop--opacity: 0.2;
//** Modal header border color
$modal-header--border-color: #e1e6ee;

//** Modal footer border color
$modal-footer--border-color: #e1e6ee;

