



































































































.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #0b0e1f;
}
.description {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #646d7d;
}
