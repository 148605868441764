

















































































































































































































































































































































































































































































































































@import '~@tko/src/styles/variables/variables';
@import '~@tko/src/styles/mixins/mixins';

$base--font-size: 14px;

.tag-picker {
  cursor: pointer;
  position: relative;

  .add-tag-button {
    background-color: transparent;
    white-space: nowrap;
    border: none;
    height: 32px;

    &.is-button {
      position: relative;
      border: 1px solid #e7ebf1;
      background-color: inherit;
      border-radius: 100px;
      padding: 5px 12px;
      display: flex;
      align-items: center;
      width: 112px;
      color: $color--text-tinted-dark;
      font-size: $base--font-size;
      line-height: $base--font-size;

      .tag-picker__chevron {
        transition: all 0.2s ease;
      }

      &.is-active {
        border-color: $indigo-80;
      }

      &.dropdown-open {
        .tag-picker__chevron {
          -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
          top: 10px;
        }
      }
    }
  }

  &__limit-msg {
    padding: 16px;
  }

  &__icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: $indigo-80;
  }

  &__chevron {
    font-size: 12px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
    font-size: 10px;
  }

  &__plus {
    font-size: 16px;
  }

  &__new-tag {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    margin-bottom: 38px;
  }

  &__new-tag-button {
    padding: 10px 8px 15px 8px;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
  }

  &__tag-indicators {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 12px;
  }

  &__indicator {
    height: 12px;
    width: 12px;
    display: block;
    background: white; //This should always be overriden by a modifier
    box-sizing: border-box;
    border-radius: 50%;
    position: relative;

    &--btn {
      width: 24px;
      height: 24px;
      will-change: transform;
      border: none !important;
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
      }
      &:focus {
        outline: none;
      }
    }

    &--btn-selected {
      transform: scale(1.2);
      &::after {
        position: absolute;
        top: 1px;
        left: 1px;
        width: 22px;
        height: 22px;
        display: block;
        content: ' ';
        border: 2px solid #fff;
        border-radius: 50%;
        box-sizing: border-box;
      }
    }
  }

  &__project-toggle {
    margin-top: 5px;
    padding: 0 8px;
    width: 100%;
    box-sizing: border-box;

    .w-toggle-switch {
      display: flex;
      justify-content: space-between;
    }

    .w-toggle-switch__label {
      padding-left: 0;
    }

    .w-toggle-switch__info-icon {
      fill: #0b0e1f;
    }
  }

  &__no-tags {
    max-width: 250px;
    text-align: center;
    padding: 60px 25px;

    &__title {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      margin-bottom: 8px;
    }

    &__description {
      font-size: 14px;
      line-height: 20px;
      color: #646d7d;
      margin-bottom: 8px;
    }
  }

  .title {
    font-size: $base--font-size;
  }
}

.is-limit .tag-picker__icon-button {
  background-color: $border-color-focus--btn-tertiary;
}

.tag-search {
  $bg: #f5f7fa !important;
  $placeholderColor: #838f9c !important;

  margin: 16px;
  background-color: $bg;

  .w-inline-search__input {
    background-color: $bg;
    @include placeholder($placeholderColor);
  }

  .w-inline-search__clear-button {
    background-color: $bg;
  }
}

.tag-lists {
  min-height: 200px;
  max-height: 280px;
  overflow-y: auto;
  margin: 8px;
  display: flex;
  flex-direction: column;
  .emptystate {
    background-color: #f5f7fa;
    border-radius: $border-radius--default;
    align-self: center;
    padding: 4px 3px;
    &__msg {
      color: $table-cell--blank-slate-color;
      font-size: $font-size--btn;
      text-align: center;
      padding: 0px;
    }
  }
}

.create-tag-form {
  margin: 8px;
}
