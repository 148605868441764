







































.comment-box {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px 0;
  position: sticky;
  bottom: 0;
  background-color: #fff;

  &__input {
    height: 60px;
    flex-grow: 2;
    background: #f5f7fa;
    border-radius: 8px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #6d6f80;

    input {
      flex-grow: 2;
      width: 100%;
      border: none;
      background: transparent;
    }

    span {
      flex-shrink: 0;
    }

    button {
      border: none;
      background: transparent;
      padding: 5px 2px;
      opacity: 0.8;

      &:active {
        opacity: 1;
      }

      svg {
        fill: #6d6f80;
        width: 14px;
        height: 14px;
      }
    }
  }
}
