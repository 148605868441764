



































































































































































































































































































































.task-time-logger {
  .timer-counter {
    width: 60px;
    display: inline-block;
  }

  button {
    &.is-play {
      .icon {
        fill: #fff;
      }
    }
    &.is-pause {
      background-color: #fce9eb;
      color: #b32334;
      .icon {
        fill: #b32334;
      }
    }
    &.is-resume {
      background-color: #d6deff;
      color: #425fd7;
      .icon {
        fill: #425fd7;
      }
    }

    &::before {
      height: 100%;
    }

    &:first-child:focus-visible::after {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    &:last-child:focus-visible::after {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
}
