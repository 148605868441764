





























.fullscreen-toggl-button {
  height: 80px;
  width: 16px;
  background: #e1e6ee;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 12px rgba(11, 14, 31, 0.12);
  border-radius: 8px 0px 0px 8px;

  &:hover {
    cursor: pointer;
  }
}
