













































$btn-color: #e1e6ee;
$icon-fill: #6d6f80;
$icon-active-fill: #4461d7;
$icon-disabled-fill: #d8e0ff;
$text-disabled: #c5cee0;

.header-segment {
  padding: 12px 0;

  &__title,
  &__content {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
  }

  &__title {
    color: #6d6f80;
    font-weight: 700;
    color: #6d6f80;
    line-height: 12px;
    margin-bottom: 8px;

    &.disabled {
      color: $text-disabled;
    }
  }

  &__content {
    color: #000;
    cursor: pointer;
    display: flex;
    align-items: center;

    &.disabled {
      color: $text-disabled;
      cursor: not-allowed;
    }

    button {
      margin-right: 8px;
    }
  }

  &__icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e1e6ee;
    width: 28px;
    height: 28px;
    border-radius: 100%;
    margin-right: 8px;

    &.is-active {
      border-color: $icon-active-fill;

      svg {
        fill: $icon-active-fill;
      }
    }

    svg {
      fill: $icon-fill;
      transform: scale(1.1);
    }

    &.disabled {
      border-color: $icon-disabled-fill;
      cursor: not-allowed;

      svg {
        fill: $icon-disabled-fill;
      }
    }

    .header-segment__content:hover:not(.disabled) & {
      border-color: $icon-active-fill;

      svg {
        fill: $icon-active-fill;
      }
    }
  }
}
