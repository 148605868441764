



































































.task-item {
  display: flex;
  list-style: none;
  padding: 13px;
  padding-left: 0;

  &__text {
    color: #0b0e1f;
    text-align: left;
    margin-left: 8px;
    padding: 2px;
    background: transparent;
    border: none;

    &--complete {
      fill: #fff;
      color: #318160;
      transition: 500ms;
    }

    &--hover {
      fill: #fff;
      color: #4461d7;
      transition: 500ms;
    }

    &--disabled {
      pointer-events: none;
    }
  }

  &__icon {
    font-weight: 600;
    display: flex;
    width: 25px;
    height: 25px;
    background: #e1e6ee;
    border-radius: 50px;
    align-content: center;
    justify-content: center;
    align-items: center;
    fill: #646d7d;

    &--complete {
      background: #4ecd97;
      color: #fff;
      fill: #fff;
      transition: 500ms;
    }

    &--hover {
      background: #4461d7;
      color: #fff;
      fill: #fff;
      transition: 500ms;

      &--complete {
        background: none;
        color: #fff;
        fill: #fff;
        transition: 500ms;
        align-items: unset;
      }
    }
  }
}
