

























.chips {
  display: flex;
  flex-wrap: wrap;

  &.overlapped {
    .chip:not(:first-child) {
      margin-left: -15px;
      box-shadow: 0 0 0 1.5px #fff;
    }
  }

  &.multiline {
    .chip {
      margin-bottom: 5px;
    }
  }
}
