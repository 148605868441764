





















.table-cell-task-name-edit {
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-right: 12px;
  a {
    cursor: pointer;
    color: #6d6f80;
    font-size: 13px;
    font-weight: 400;
    span {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
