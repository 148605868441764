























































































































































































































































































































































































































































































































@import '~@tko/src/styles/variables/variables';
$font-size--base: 14px;
$width--time-input: 49px;
$height--time-input: 32px;

.table-cell-time {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    .table-cell-time__edit-icon {
      display: flex;
    }
  }

  &__edit-icon {
    display: none;
    align-items: center;
    justify-content: flex-end;
    width: 45px;
    height: 100%;
    padding-right: 15px;
    background: linear-gradient(90deg, transparent 0%, #f5f7fa 45%);
    position: absolute;
    right: 0;
    top: 0;
  }

  &__placeholder-text {
    font-size: $font-size--base;
    color: #4461d7;
    background-color: $white;
    width: 100%;
    height: 100%;
    border-radius: $border-radius--default;
    color: $indigo-30;
    font-size: $font-size--btn;
  }

  .empty-button {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &.empty-button-logged-time {
      background: white;
      height: calc(100% - 8px);
      width: calc(100% - 8px);
      border-radius: 8px;
    }
  }

  .container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .btn {
      font-size: 13px;
      width: 100%;
      position: relative;

      &:hover {
        color: inherit;
      }

      &.btn-timer-toggle {
        display: flex;
        height: 20px;
        width: 20px;
        padding: 0;
        border-radius: 50%;
        align-content: center;
        justify-content: center;
        background: #0b0e1f;
        color: white;

        svg {
          display: flex;
          margin: 0;
          height: 8px;
          font-size: 8px;
          margin-top: 5px;
        }

        &.btn-pause {
          background: #e12d42;

          &:hover {
            background: #cb283b;
          }
        }

        &:hover {
          background: #646d7d;
        }

        &:focus {
          outline: none;
        }
      }

      &.btn-link {
        color: #0b0e1f;
        padding: 0;
        width: auto;
      }
    }
  }
}
