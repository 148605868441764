



























































































































































































.c-people-picker {
  margin: auto;
  &__wrapper {
    height: 24px;
  }
}
.disabled {
  opacity: 0.5;
}
