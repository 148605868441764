//== Tables
//

//** Default background color used for all tables.
$table--background-color: transparent;
//** Background color used for `.table-striped`.
$table-accent--background-color: $background-color--light;
//** Background color used for `.table-hover`.
$table-hover--background-color: $background-color--default;
$table-active--background-color: $table-hover--background-color;

//** Border color for table and cell borders.
$table--border-color: $border-color--default;

$background-color--table-stripped: $background-color--light;


$table-cell--blank-slate-color: #646d7d;
