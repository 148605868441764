















































































































































































































































































@import '~@tko/src/styles/mixins/mixins';
@import '~@tko/src/styles/variables/variables';

.notifications-loader {
  @include scrollbar();
  width: 100%;
  flex: 1;
  min-height: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.notifications-loader__scroll-to-top-btn {
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: #3f4650;
  display: flex;
  align-items: center;
  background: #fff;
  /* Neutral/10 */
  border: 1px solid $neutral-10;
  /* Elevation/Extra small */
  box-shadow: 0px 0px 12px rgba(11, 14, 31, 0.08);
  border-radius: 60px;
  transition: opacity 0.5s ease-in-out;
  height: 40px;
  text-align: center;
  padding: 13px 16px;
  line-height: 24px;
  font-size: 14px;

  &:hover {
    background: $neutral-10;
  }

  &:active {
    background: $neutral-20;
  }
}

.notifications-loader__scroll-to-top-btn-text {
  margin-left: 12px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.u-blank-slate {
  margin: 0;
  height: 100%;
  padding-left: 72px;
  padding-right: 72px;

  .blank-slate__image {
    max-height: 96px;
  }
}
