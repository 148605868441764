







































































































@import '@teamwork/ui-design-tokens/dist/web/tokens.scss';

.TableViewSection {
  &__header {
    display: flex;
    align-items: center;
    position: sticky;
    left: 0;
    z-index: 50;
    background: $token-ui-white;
    font-size: 18px;
    line-height: 48px;
    font-weight: 600;
    color: #0b0e1f;
    &--shadow {
      box-shadow: 0 8px 20px rgba(11, 14, 31, 0.06);
      border-bottom: 1px solid #f5f7fa;
    }
  }
  &__toggle {
    cursor: pointer;
    margin-right: 8px;
    transition: all 0.3s ease;
    transform: rotate(-90deg);
    &--open {
      transform: rotate(0deg);
    }

    svg {
      height: 16px;
      width: 16px;
    }
  }
}
