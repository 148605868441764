





































































































































































.checklist-panel {
  ::v-deep .panel-header {
    padding: 0px 0px 0px 25px;
    height: 60px;
  }

  ::v-deep .panel {
    width: 25vw;
    min-width: 350px;
  }

  ::v-deep .panel-body {
    width: 25vw;
    min-width: 350px;
  }

  ::v-deep .panel-header {
    width: 25vw;
    min-width: 350px;
  }
}

.title {
  bottom: 0px;
  padding: 0px;
  margin-bottom: 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  color: #0b0e1f;
  font-size: 22px;
}

.video-container {
  border-radius: 8px;
  overflow: hidden;
  flex: 0 0 auto;

  .preview {
    position: relative;

    &__thumbnail {
      width: 100%;
      cursor: pointer;
      filter: brightness(50%);
    }

    &__button {
      width: 100px;
      height: 100px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      box-shadow: 0px 8px 24px rgba(11, 14, 31, 0.16);
      font-size: 16px;
      border: none;
      cursor: pointer;
      border-radius: 100px;
      animation: button_pulse 1200ms ease-out infinite;
    }
    &__icon {
      fill: #4ecd97;
      font-size: 84px;
    }
  }
}
$pulseColour: #ffffff;
@keyframes button_pulse {
  from {
    box-shadow: 0 0 0 0 rgba($pulseColour, 1);
  }
  to {
    box-shadow: 0 0 0 12px rgba($pulseColour, 0);
  }
}

.onboarding-checklist {
  display: flex;
  position: absolute;
  flex-direction: column;
  font-family: Open Sans;
  font-style: normal;
  font-size: 14px;
  background: #fff;
  padding: 0px 25px 25px 25px;
  transition: 600ms;
  width: 100%;
  max-height: calc(100vh - 66px);

  &__subtitle {
    color: #646d7d;
    margin-bottom: 20px;
  }

  &__close-btn {
    background: transparent;
    border: none;
  }
  &__progress-bar {
    height: 12px;
    margin: 27px 0px 17px 0px;
    display: flex;
    width: 100%;
    background-color: #e1e6ee;
    border-radius: 24px;
  }
  &__progress-bar-fill {
    display: block;
    height: 12px;
    background-color: #4461d7;
    border-radius: 16px;
    transition: width 500ms ease-in-out;
  }
  &__dismiss-text {
    background: transparent;
    border: none;
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: rgb(161, 161, 161);
  }

  .tasklist {
    padding: 0;
    overflow: auto;
    margin-top: 10px;
    flex: 1 1 auto;

    &__load-state {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
    }
  }
}
