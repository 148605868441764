




































































.w-item-picker__item {
  .btn {
    position: relative;
    border-radius: 50px;
    border: none;
    padding: 0 12px;
    margin-right: 8px;
    color: #fff;
    max-width: 210px;
    font-size: 12px;
    height: 24px;
    line-height: 24px;

    &.is-active {
      padding: 0 25px 0 12px;
    }

    span {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;
    }

    svg {
      fill: #fff;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
