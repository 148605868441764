
































































































































































































































@import '~@tko/src/styles/variables/variables';
@import 'src/styles/components/widgets/_w-tooltip.scss';

.assignees {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-self: center;

  &__popover-tooltip-content,
  &__popover-extra-tooltip-content {
    padding: $padding--w-tooltip__inner;
    background-color: $background--w-tooltip;
    color: $text--w-tooltip;
    border-radius: $border-radius--w-tooltip;
    font-size: $font-size--w-tooltip__inner;
  }

  .assignee,
  &__popover-extra {
    width: var(--avatar-size);
    height: var(--avatar-size);
    margin-left: var(--avatar-offset);
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 0px 1px #fff;

    &:first-of-type {
      margin-left: 0;
    }

    img,
    .trigger {
      width: var(--avatar-size);
      height: var(--avatar-size);
    }

    span {
      font-size: 13px;
      color: #fff;
      display: block;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
    }
  }
  .company {
    background-color: #eee;
  }

  .assignee:not(:first-child),
  &__popover-extra {
    animation: slide 1s forwards;
    animation-timing-function: cubic-bezier(0.33, 0, 0.18, 0.99);
  }

  @keyframes slide {
    0% {
      transform: translateX(
        calc(var(--assignee-bubble-order) * calc(var(--avatar-size) / -2) + 4px)
      );
    }
  }

  &.is-disabled {
    pointer-events: none;
  }
}
