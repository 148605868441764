






























































.menu-element {
  .trigger {
    display: block !important;
  }

  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 24px;
    text-decoration: none;
    user-select: none;
    font-size: 14px;
    line-height: 19px;
    color: inherit;

    &:hover {
      background-color: #f5f7fa;
      cursor: pointer;
      color: inherit;
    }
  }

  &__icon {
    margin-right: 7px;
  }

  &__text {
    word-break: break-word;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
  }

  &__subtitle {
    font-size: 12px;
    font-weight: 400;
    margin-left: 8px;
    color: #646d7d;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
  }
}
