//== Modals
//
//##

//** Padding applied to the modal body
$modal-inner--padding: 20px;

//** Padding applied to the modal title
$modal-title--padding: $modal-inner--padding;
//** Modal title line-height
$modal-title--line-height: $line-height--default;

$modal--width: 900px;
$modal-medium--width: 640px;
$modal-small--width: 480px;