































@import '@teamwork/ui-design-tokens/dist/web/tokens.scss';

.task-item-container {
  border: 1px solid #f5f7fa;
  border-bottom: 0;
  padding: 0 8px;
  font-size: 12px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: -1px;

  &:hover {
    background-color: #f5f7fa;
  }

  ::v-deep {
    .task-toggle-button {
      z-index: 3;
    }
  }
  &:last-child {
    border: 1px solid #f5f7fa;
  }
  .content {
    display: flex;
    align-items: center;
    flex-grow: 2;
    overflow: hidden;
  }
  .actions {
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }
  ::v-deep .assignees {
    width: auto;
  }
}
