





























.table-cell-text {
  padding: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #0b0e1f;

  &__link {
    color: inherit;
    &:hover {
      color: inherit;
      text-decoration: underline;
    }
  }
}
