

























































@import '~@tko/src/styles/variables/_variables';

.w-avatar {
  border-radius: 100%;
  background-size: 100%;
  overflow: hidden;
  background-color: #8c99a2;

  img {
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity $transition-duration--default;
  }

  &--loaded {
    img {
      opacity: 1;
    }
  }
}
