























.task-item-options {
  background: white !important;
}
