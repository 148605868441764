$bg--green: #9bdabb;
$bg--amber: #ffdd8c;
$bg--red: #dd767f;
$bg--dark-blue: #8f9eda;
$bg--light-blue: #dadff7;
$bg--picker-light-blue: #f5f7fa;
$bg--picker-dark-blue: #4461d7;
$bg--not-set: #f5f5f5;
$text-color: #2e2e2f;
$text-color-green: #46B988;
$text-color-red: #e12d42;
$text-color-amber: #FF7641;
$main-header-text-color: #0b0e1f;
$button-complete-green: #4ecd97;

// New Report UI colors
$bg-amber-light: #FFF0CA;
$text-color-amber-dark: #982902;
$bg-red-light: #FFD0DA;
$text-color-red-dark: #8F0110;
$bg-green-light: #D2FDE8;
$text-color-green-dark: #0E5E3D;
$text-color-grey-dark: #4C5565;


$reporting-colors: (
    bgGreyLight: $bg--picker-light-blue,
    bgGreyDark: $text-color-grey-dark,
    bgAmberLight:$bg-amber-light,
    bgAmberDark:$text-color-amber-dark,
    bgRedLight:$bg-red-light,
    bgRedDark:$text-color-red-dark,
    bgGreenLight:$bg-green-light,
    bgGreenDark:$text-color-green-dark,
    bgGreen: $bg--green,
    bgAmber: $bg--amber,
    bgRed: $bg--red,
    bgDarkBlue: $bg--dark-blue,
    bgLightBlue: $bg--light-blue,
    bgNotSet: $bg--not-set,
    textColor: $text-color,
    mainHeaderTextColor: $main-header-text-color,
);

:export {
    @each $name, $color in $reporting-colors {
        #{$name}: $color;
    }
}

