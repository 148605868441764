

































































.app-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.app-error-svg {
  display: flex;
  align-items: center;
  height: 100%;
}
