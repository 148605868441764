





















































.avatar:hover .avatar-tooltip {
  display: block;
}
.avatar-tooltip {
  bottom: var(--token-c-tooltips-size-bottom);
  display: none;
}

.avatar > svg {
  /* the SVG in the designs has a proportion of 2.5 over the width of the avatar */
  width: var(--token-c-tooltips-size-svg-size);
  height: var(--token-c-tooltips-size-svg-size);
}
