
































.dependnecy-status-picker-item {
  padding: 8px 16px;
  cursor: pointer;
  &__selected {
    background: #f5f7fa;
    pointer-events: none;
  }
  &:hover {
    background: #f5f7fa;
  }
  &__status {
    margin-right: 8px;
  }
  &__title {
    font-weight: 600;
  }
  &__description {
    margin-top: 4px;
    margin-left: 32px;
  }
}
