


























































































































































.drag-area {
  position: absolute;
  z-index: 102;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 110px);
  padding: 0 25px 16px 25px;
  transition: 0.1s opacity ease-out;
  opacity: 0;
  visibility: hidden;
  &.commentVisible {
    height: calc(100% - 320px);
  }
  &.visible {
    opacity: 1;
    visibility: visible;
    .drag-content {
      display: flex;
    }
  }
  &.hover {
    .drag-content {
      border: 2px solid #4461d7;
      background-color: rgba(white, 1);
    }
  }
  .drag-content {
    display: none;
    width: 100%;
    height: 100%;
    background-color: rgba(white, 0.8);
    transition: 0.1s background-color ease-out;
    border: 2px dashed #4461d7;
    border-radius: 8px;
    // display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: #0b0e1f;
    p {
      margin-top: 16px;
    }
  }
}
