// Main Text colors
$color--text-default: #555555;
$color--text-primary: $color--text-default;
$color--text-secondary: #999999;
$color--icon-default: #aaa;
$color--text-placeholder: #aaa;
$color--text-sub: #aaa;
$color--text-bright: $white;
$color--text-secondary-bright: darken($white, 5%);
$color--elements: $color--text-secondary;
$color--impersonating: #b94a48;
$color--light-blue: #63a9f9;
$color--text-tinted-dark: #0b0e1f;
$color--text-tinted-secondary: #D6D7DA;

$color--heading-dark: #222222;
$color--heading: #333333;

$color--text-alt: #777;
$color--text-secondary-alt: #999;
$color--filter: #4461D7;
$color--link: #3D8AED;
$color--link-new: #4461D7;
$color--primary: $indigo-30;
$color--new-primary: #4461d7;
$color--link-hover: $color--light-blue;
$color--success: #59C894;
$color--error: #ff6562;
$color--info: #f2825b;
$color--warning: #ffc486;
$color--danger: $color--error;
$color--today: #6CB3FF;
$color--late: #ff6461;
$color--upcoming: #febe6f;

$color--default-alert: #2196f3;
$color--error-alert: #f44336;
$color--card-expiry-alert: #9c27b0;
$color--privacy-alert: #d36e69;
$color--trial-expiry-alert: #4461D7;

$color--low-risk: #fbbd75;
$color--medium-risk: #fd905d;
$color--high-risk: #fc6565;

$color--metric-info: #9ca9b5;
$color--metric-unbilled: #febe6f;
$color--metric-billed: #138a5a;
$color--metric-billable: #6076da;
$color--metric-non-billable: #b1bac5;
