






































.date-label {
  ::v-deep .table-cell-date {
    display: inline;
    padding: 0;
    color: #000;

    * {
      width: auto !important;
    }

    .placeholder {
      font-size: inherit !important;
      line-height: inherit !important;
    }
  }
}

.date-label {
  text-align: left !important;
}

.w-empty-state-cell {
  justify-content: flex-start !important;
}
