





























































































$sizeList: (
  small: 16px,
  default: 24px,
  medium: 32px,
  big: 40px,
);

$sizes: small, default, medium, big;

.chip {
  border-radius: 20px;
  margin-right: 8px;
  color: #0b0e1f;
  font-size: 13px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.interactive {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.7;
    }
  }

  &.has-image {
    overflow: hidden;

    img {
      border-radius: 50%;
    }
  }

  &__label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__remove {
    background: none;
    border: none;
    padding: 2px 6px;
    font-weight: bold;
    font-size: 15px;
    opacity: 0.5;

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 1;
    }
  }

  &.has-icon {
    &.has-label {
      .chip__label {
        padding-left: 0;
      }
    }
  }

  &.has-label {
    &.has-remove {
      .chip__label {
        padding-right: 0;
      }
    }
  }

  /* SIZES */
  @each $size in $sizes {
    &.size-#{$size} {
      height: map-get($sizeList, $size);

      .chip__label {
        padding: 0 map-get($sizeList, $size) / 2;
        @if $size == 'small' {
          font-size: 12px;
          font-weight: 400;
        }
      }

      &.has-image {
        min-width: map-get($sizeList, $size);
        img {
          height: map-get($sizeList, $size);
        }
      }

      svg {
        margin: 0 map-get($sizeList, $size) / 4;
      }
    }
  }
}
