




























.skeleton-header-segment {
  margin-right: 20px;

  &__content {
    margin-top: 4px;
    display: flex;
    align-items: center;
  }

  &__icon {
    margin-right: 8px;
  }
}
