





















































.list-item {
  border-top: 1px solid #2a2f40;
}

.list-item:last-child,
.list-item:only-child {
  border-top: 1px solid #2a2f40;
  border-bottom: 1px solid #2a2f40;
}

@media screen and (max-height: 740px) {
  .hide-smaller-height {
    display: none !important;
  }
}
