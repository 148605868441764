











































.task-context-menu {
  &__list {
    list-style-type: none;
    padding: 5px 0 5px 0;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    height: 35px;
    cursor: pointer;

    &:hover {
      background-color: #f5f7fa;
    }
  }
}
