





























































































.variant-primary:focus-visible,
.variant-secondary:focus-visible,
.variant-warning:focus-visible,
.variant-slate:focus-visible,
.variant-ghost:focus-visible,
.variant-outline:focus-visible {
  position: relative;
}
.variant-primary:focus-visible::after,
.variant-secondary:focus-visible::after,
.variant-warning:focus-visible::after,
.variant-slate:focus-visible::after,
.variant-ghost:focus-visible::after,
.variant-outline:focus-visible::after {
  content: '';
  position: absolute;
  border: 1px solid var(--token-color-white);
  left: 2px;
  top: 2px;
  right: 2px;
  bottom: 2px;
  border-radius: 2px;
}
.variant-primary:focus-visible.rounded-full::after,
.variant-secondary:focus-visible.rounded-full::after,
.variant-warning:focus-visible.rounded-full::after,
.variant-slate:focus-visible.rounded-full::after,
.variant-ghost:focus-visible.rounded-full::after,
.variant-outline:focus-visible.rounded-full::after {
  border-radius: var(--token-border-radius-full);
}
.variant-secondary:focus-visible::after,
.variant-outline:focus-visible::after {
  border-color: var(--token-color-primary);
}

.btn-slot svg {
  height: 16px;
  fill: currentColor;
}
