



















































































.timelogs-count {
  color: #0b0e1f;
  border: 1px solid #e1e6ee;
  border-radius: 20px;
  min-height: 28px;
  padding: 0 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .counter {
    margin-left: 4px;

    span {
      font-weight: 600;
    }
  }

  &__summary {
    padding: 16px;
    min-width: 180px;
    display: flex;
    flex-direction: column;
    gap: 4px;

    .time {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      line-height: 24px;

      &:first-child {
        font-weight: 600;
      }
    }
  }

  .icon {
    fill: #646d7d;
  }
}

.v-popover {
  &.open {
    .timelogs-count {
      border-color: #4461d7;
    }

    svg {
      fill: #4461d7;
    }
  }
}
