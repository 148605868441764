



















































































































































































































































































@import '~@tko/src/styles/variables/_variables';

$typeColors: (
  comment: #95e1c1,
  company: #e1e6ee,
  event: #bba2ff,
  file: #a1e9f0,
  form: #dfa275,
  integration: #ffffff,
  invoice: #646d7d,
  link: #ffe29b,
  message: #ffa0dd,
  message_comment: #ffa0dd,
  milestone: #8f9fe7,
  notebook: #ed818e,
  proof: #94c7fb,
  project: #94c7fb,
  status: #e1e6ee,
  task: #ffad8d,
  tasklist: #ffad8d,
  team: #e1e6ee,
  user: #e1e6ee,
  reaction: #ffffff,
);

.w-entity-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: 22px;
  height: 22px;

  &--invalid {
    display: none;
  }

  svg {
    fill: #646d7d; // Use 'Neutral 50' design token when available
  }

  &-background {
    svg {
      fill: #0b0e1f; // Use 'Neutral 60' design token when available
    }

    @each $type, $color in $typeColors {
      &--#{$type} {
        background-color: $color;
      }
    }
  }

  img {
    max-width: 80%;
  }
}
