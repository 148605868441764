// Use 'Neutral 20' design token when available
// Use 'Neutral 30' design token when available
@mixin skeleton($shimmer: true, $color: #e1e6ee, $shimmer-color: #c5cee0) {
  position: relative;
  overflow: hidden;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    border-color: transparent;
    border-radius: 4px;
    background-color: #e1e6ee !important;
  }

  @if $shimmer {
    &::after {
      animation: shimmer 4s infinite linear;
      background: linear-gradient(to right, $color 4%, $shimmer-color 25%, $color 36%);
      background-size: 1000px 100%;
    }

    @keyframes shimmer {
      0% {
        background-position: -1000px 0;
      }
      100% {
        background-position: 1000px 0;
      }
    }
  }
}
