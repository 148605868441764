














































































.v-popover {
  display: inline-block;
}

.upload-menu {
  list-style: none;
  background-color: #fff;
  padding: 0;
  border-radius: 4px;
  padding: 5px 0;
  li {
    button {
      appearance: none;
      border: none;
      background: transparent;
      display: flex;
      font-size: 14px;
      width: 100%;
      height: 32px;
      padding: 7px 13px;
      &:hover {
        background: #f5f7fa;
      }
    }
  }
}
