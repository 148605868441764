





























































































































h2 {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 6px;
  display: flex;
  align-items: center;
  color: #646d7d;
}
.help-center-items {
  & button {
    margin-bottom: 8px;
  }
  & button:last-child {
    margin-bottom: 0;
  }
}
