@mixin preloader($size, $border-size, $background-color) {
    width: $size;
    height: $size;
    border: $border-size solid rgba($background-color, 0.5);
    border-top-color: $background-color;
    border-radius: 50%;
    animation: loadingCircle 1s ease infinite;
    will-change: transform;
    backface-visibility: hidden;
}

@mixin preloader-icon {
    animation: loadingCircle 1s ease infinite;
    will-change: transform;
}

@keyframes loadingCircle {
    100% {
        transform: rotate(360deg) translate3d(0, 0, 0);
    }
}
