









































.help-center-items {
  & button {
    margin-bottom: 8px;
  }
  & button:last-child {
    margin-bottom: 0;
  }
}
