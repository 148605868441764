








































































































































































































































































::v-deep .w-full.contact-upload.relative span {
  color: #3b4ca5;
  font-weight: bold;
}

::v-deep .multiselect {
  @apply h-10;
  &__tags {
    @apply h-10 flex items-center justify-center;
  }

  &__select {
    @apply top-[4px] right-1;
  }
}

input {
  /* White/10 */

  background: #ffffff;
  /* Neutral/20 */

  border: 1px solid #e1e6ee;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;

  &.error {
    border: 1px solid red;
  }

  &:focus {
    &.error {
      border: 1px solid red;
    }
  }
}
.input--has-error {
  border: 1px solid #e12d42;
}

.error-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #e12d42;
  font-size: 12px;
  line-height: 16px;
  padding-top: 8px;
  display: block;
}
