



































































































$hover-color: #4461d7;
$border-color: #e1e6ee;
// .cursor-not-allowed {
//   cursor: not-allowed !important;
// }
// .cursor-pointer {
//   cursor: pointer !important;
// }
.custom-field-dropdown {
  width: 100%;
  padding: 0;
}

.border-gray {
  border: 1px solid $border-color;
}

.board-container {
  &:hover {
    color: $hover-color;
    .border-gray {
      border: 1px solid $hover-color;
    }
  }
}
