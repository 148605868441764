






































.item-picker-no-data {
  width: 100%;
  display: flex;
  justify-content: center;

  &__wrapper {
    text-align: center;
  }

  &__icon {
    width: 52px;
    margin-bottom: 8px;
    margin-top: 8px;
  }

  &__title {
    font-size: 20px;
    line-height: 36px;
    font-weight: 600;
    color: #0b0e1f;
    margin-bottom: 0px;
  }

  &__description {
    font-size: 14px;
    line-height: 24px;
    color: #0b0e1f;
    max-width: 235px;
  }

  &__btn {
    margin-top: 24px;
    margin-bottom: 24px;
    background-color: #4461d7;
    border-color: #4461d7;
    color: white;
    padding: 8px 16px;
    font-size: 14px;
    .w-svg-sprite {
      margin-right: 2px;
    }
    &:hover {
      color: white;
      background-color: darken(#4461d7, 7%);
    }
  }
}
