






































































































.log-time {
  display: flex;
  align-items: center;

  &__logged {
    font-size: 12px;
    color: #0b0e1f;
    margin-right: 16px;
    white-space: nowrap;
    font-weight: 600;
  }

  &__timer {
    font-size: 12px;
    font-weight: 600;
    color: #0b0e1f;
    margin-right: 10px;
    white-space: nowrap;
  }

  &__wrapper {
    display: flex;
    position: relative;
    width: 57px;
    height: 28px;
    z-index: 103;
    margin-right: 8px;
  }
}
