@import '~@tko/src/styles/variables/variables';

// Variables
$font-size--multiselect: 13px;
$height--multiselect: 32px;
$z-index: 102;
$option-font-height--multiselect: 18px;
$option-vpadding--multiselect: ($height--multiselect - $option-font-height--multiselect) / 2;
$input-font-height--multiselect: 20px;
$input-vpadding--multiselect: ($height--multiselect - $input-font-height--multiselect - 2) / 2;
$bgcolor--multiselect--highlight: #5897fb;
$bgcolor--multiselect--disabled: #ededed;
$select-caret-color: #0b0e1f;

// Structure
.w-select-multi {
    &.multiselect {
        overflow: hidden;
        box-sizing: border-box;
        min-height: $height--multiselect;
        font-size: $font-size--multiselect;

        &--active {
            overflow: unset;
        }
    }
}

// Elements
//  sass-lint:disable no-mergeable-selectors, force-element-nesting
.multiselect {
    position: relative;

    &__content {
        // Without this, the dropdown list overflows its parent + doesn't ellipsis
        display: block !important; //sass-lint:disable-line no-important
        list-style: none;
        padding: 0;
        margin: 0;
        min-width: 100%;
    }

    &__tags {
        min-height: $height--multiselect;
        display: block;
        padding: 5px 40px 0 8px;
        border-radius: 5px;
        border: 1px solid #e8e8e8;
        background: #fff;
        font-size: 14px;
    }

    &__select {
        width: $height--multiselect;
        height: $height--multiselect;
        overflow: hidden;
        position: absolute;
        right: 1px;
        top: 1px;
        padding: 4px 8px;
        text-align: center;
        -webkit-transition: -webkit-transform .2s ease;
        transition: -webkit-transform .2s ease;
        transition: transform .2s ease;
        transition: transform .2s ease, -webkit-transform .2s ease;

        &::before {
            border-color: transparent;
            content: '\f107';
            top: 25%;
            font-size: 18px;
            color: $select-caret-color;
            font-family: 'FontAwesome';
        }
    }

    &__content-wrapper {
      position: absolute;
      display: block;
      background: #fff;
      width: 100%;
      max-height: 240px;
      overflow: auto;
      border: 1px solid #e8e8e8;
      border-top: none;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      z-index: 1;
    }

    &__element {
      display: block;
    }

    &__spinner {
        width: $height--multiselect;
        height: $input-font-height--multiselect;
        top: $input-vpadding--multiselect;
    }

    &__input,
    &__placeholder,
    &__single {
        font-size: $font-size--multiselect;
        line-height: $input-font-height--multiselect;
        margin-bottom: $input-vpadding--multiselect;
        vertical-align: top;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: relative;
        display: inline-block;
        min-height: 20px;
        border: none;
        border-radius: 5px;
        background: #fff;
        padding: 0 0 0 5px;
        width: 100%;
        -webkit-transition: border .1s ease;
        transition: border .1s ease;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;

        &:focus {
          border-color: #a8a8a8;
          outline: none;
        }
    }

    &__placeholder {
        padding-top: 0;
    }

    &__option {
        min-height: $height--multiselect;
        padding: $option-vpadding--multiselect;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        padding: 12px;
        min-height: 40px;
        line-height: 16px;
        text-decoration: none;
        text-transform: none;
        position: relative;
        cursor: pointer;

        &--highlight {
            background-color: $bgcolor--multiselect--highlight;
            outline: none;
            color: $white;
        }

        &--disabled {
            background-color: $bgcolor--multiselect--disabled;
        }

        &--group {
            background: inherit; // remove Vue-multiselect group background
        }

        &--selected {
            font-weight: inherit;
        }

        &--selected#{&}--highlight {
            background-color: $bgcolor--multiselect--highlight;
        }

        .w-select-multi--nested-option {
            padding-left: 13px;
        }
    }

    &__loading-more-results {
        opacity: 0.6;
        min-height: $height--multiselect;
        padding: $option-vpadding--multiselect;
        margin-top: -1px; // allows intersection observer to fire from last option
        padding-top: $option-vpadding--multiselect + 1px;
    }

    &__clear {
        position: absolute;
        right: 35px;
        top: 10px;
        height: 15px;
        width: 15px;
        display: block;
        cursor: pointer;
        z-index: 3;
        border: none;
        background: inherit;
    }
}

// Modifiers
.multiselect {
    $self: &;

    &--disabled {
        background-color: transparent; // avoids colouring the bottom padding (~8px)

        #{$self}__current,
        #{$self}__select,
        #{$self}__single,
        #{$self}__tags,
        #{$self}__spinner {
            background-color: $bgcolor--multiselect--disabled;
        }
    }

    &--active {
        z-index: $z-index;

        #{$self}__select {
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
        }

        #{$self}__placeholder {
          display: none;
        }
    }
}

.w-select-multi {
    $input-background-color: $neutral-20;
    $input-background-color--light: #ffffff;
    $focus-background: #f5f7fa;
    $text-color--dark: #0b0e1f;
    $focus-color: #4461d7;

    &--wizard {
        &.multiselect {
            border: 1px solid transparent;
            border-radius: 3px;
            color: $text-color--dark;
        }

        &.multiselect--active {
            border: 1px solid $focus-color;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom-color: transparent;
        }

        .multiselect__tags,
        .multiselect__select {
            height: 40px;
        }

        .multiselect__select {
            width: auto;
            padding: 8px 16px;
        }

        .multiselect__tags {
            padding-left: 12px;
            padding-top: 9px;
            background: $input-background-color;
            border: 0;
        }

        .multiselect__single,
        .multiselect__input {
            font-size: 14px;
            background: transparent;
            color: $text-color--dark;

            &::placeholder {
                color: $text-color--dark;
            }
        }

        .multiselect__placeholder {
            font-size: 14px;
            background: $input-background-color;
        }

        .multiselect__content-wrapper {
            border: 1px solid $focus-color;
            margin-left: -1px;
            margin-right: 1px;
            border-top: 0;
        }

        .multiselect__option--highlight {
            background-color: $focus-color;
            color: $white;
        }

        .multiselect__option--selected,
        .multiselect__option--selected.multiselect__option--highlight {
            background-color: $focus-background;
            color: $text-color--dark;
        }

        .multiselect__spinner {
            display: none;
        }
    }

    &--wizard__light {
        .multiselect {
            border-radius: 4px;
            color: $text-color--dark;
        }

        .multiselect__tags {
            border: 1px solid transparent;
            height: 40px;
            padding-left: 5px;
            padding-top: 9px;
            border: 0;
            background: $input-background-color--light;
        }

        .multiselect__select {
            height: 40px;
            width: auto;
            padding: 8px;
        }

        .multiselect__clear{
            top: 12px;
        }

        .multiselect__content-wrapper {
            border: 1px solid #e8e8e8;
            margin-left: -1px;
            margin-right: 1px;
            border-radius: 4px;
        }

        .multiselect__placeholder,
        .multiselect__input {
            color: $text-color--dark;
        }

        .multiselect__option--highlight {
            background-color: $focus-color;
            color: $white;
        }
        
        .multiselect__option--selected,
        .multiselect__option--selected.multiselect__option--highlight {
            background-color: $focus-background;
            color: $text-color--dark;
        }
        .multiselect__avatar--img{
            width: 24px;
            height: 24px;
            margin-right: 5px;
            border-radius: 100%;
            overflow: hidden;
        }
    }

}

.w-project-template-picker {
    .multiselect__single,
    .multiselect__input,
    .multiselect__placeholder {
        font-size: 12px;
        font-weight: 600;
        padding: 0;
    }

    .multiselect__single,
    .multiselect__placeholder {
        color: #0b0e1f;
        text-align: center;
    }

    .multiselect__content {
        text-align: left;
    }

    .multiselect__tags {
        height: 32px;
        padding: 5px 25px 0 12px;
    }

    .multiselect__select {
        height: 28px;
        padding: 3px 8px;
        z-index: 1;
        right: 28px;
    }

    &.multiselect--active {
        .multiselect__tags,
        .multiselect__input {
            background-color: $neutral-20;
        }
    }

    .multiselect__spinner {
        display: none;
    }
}
