









































































































































































































































































































.LoaderState {
  display: flex;

  &--loadOnScroll-top {
    flex-direction: column-reverse;
  }
  &--loadOnScroll-right {
    flex-direction: row;
  }
  &--loadOnScroll-bottom {
    flex-direction: column;
  }
  &--loadOnScroll-left {
    flex-direction: row-reverse;
  }
}
