




















.slide-enter-active,
.slide-leave-active {
  transition-property: transform, opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transform: translateX(0%);
  opacity: 1;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}

/* This is too sluggish when using it on pinned panel 
  @TODO - see if we can improve it
*/
.slide-width-enter-active,
.slide-width-leave-active {
  transition-property: transform, width;
  transition-timing-function: linear;
  transition-duration: 100ms;
  transform: translateX(0%);
}

.slide-width-enter,
.slide-width-leave-to {
  transform: translateX(-100%);
  width: 0px;
}
