

























































.w-item-picker__list {
  list-style: none;
  margin: 0;
  padding: 0;

  &.list-mode--horizontal {
    display: flex;
    flex-direction: row;
    row-gap: 8px;
  }

  &.list-mode--vertical {
    margin: 0 8px;

    .w-item-picker__item {
      margin: 8px 0;
    }
  }

  &.list-wrap--wrap {
    flex-wrap: wrap;
  }
}
