




















































































































































































.dependencies-item-task {
  display: flex;
}
.task-assignee {
  margin-right: 16px;
  display: flex;
}
