

























































$hover-bg: #f5f7fa;
$focus-outline: #4461d7;
$text-color: #0b0e1f;

.task-name {
  flex-grow: 2;

  textarea {
    font-size: 20px;
    color: $text-color;
    border: none;
    outline: none;
    padding: 8px;
    width: 100%;
    border-radius: 8px;
    font-weight: 600;
    resize: none;

    &:hover,
    &:focus {
      background-color: $hover-bg;
    }

    &:focus {
      box-shadow: 0 0 0 1px $focus-outline;
    }

    &::placeholder {
      font-weight: 400;
    }
  }
}
