
























.table-cell-time {
  padding: 0 10px;
}
