















































































































































































































































































































@import '@teamwork/ui-design-tokens/dist/web/tokens.scss';

.TableViewColumnPicker {
  &__title {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    padding: 16px 16px 0;
  }

  &__more {
    display: flex;
    flex-direction: column;
    cursor: default;
    fill: #8a8d99;
    &:hover,
    &--open {
      background-color: $token-ui-bg-hover;
    }
  }

  &__trigger {
    font-size: 12px;
    cursor: pointer;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: $token-size-px solid $token-ui-primary;
    border-radius: 12px;
    background: $token-ui-bg;
    fill: $token-ui-black;

    &:hover,
    &--open {
      background: $token-ui-primary;
      fill: $token-ui-white;
    }
  }

  &__add-custom-field {
    padding: 0;
    outline: none;
    background-color: transparent;
    border: none;
    display: inline-flex;
    align-items: center;
    color: #4461d7;
    font-weight: 600;

    &:hover {
      text-decoration: underline;
    }

    .plus-sign {
      display: inline-flex;
      width: 16px;
      height: 16px;
      margin-right: 4px;
      position: relative;
      align-items: center;

      svg {
        fill: #4461d7;
      }
    }
  }
}
