














































































































































































@import '~@tko/src/styles/variables/variables';

$font-size--base: 14px;
$width--time-input: 49px;
$height--time-input: 32px;

.table-cell-time {
  padding: $padding--medium;
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    .table-cell-time__edit-icon {
      display: flex;
    }
  }

  &__placeholder-text {
    font-size: $font-size--base;
    color: $indigo-30;
    margin-left: -12px;
    width: 100%;
    height: 100%;
    background-color: $white;
    border-radius: $border-radius--default;
  }

  &__edit-icon {
    display: none;
    align-items: center;
    justify-content: flex-end;
    width: 45px;
    height: 100%;
    padding-right: 15px;
    background: linear-gradient(90deg, transparent 0%, #f5f7fa 45%);
    position: absolute;
    right: 0;
    top: 0;
  }

  .btn {
    font-size: 13px;
    width: 100%;
    position: relative;

    &:hover {
      color: inherit;
    }
  }
}

.estimate-popover {
  padding: 24px 16px 16px;
  font-weight: 600;

  &__header {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: $font-size--medium;
    margin-bottom: $margin--large;
    color: $color--text-tinted-dark;
  }

  &__body {
    display: flex;
    align-items: center;
    margin-bottom: $margin--large;
  }

  &__time-input {
    width: $width--time-input;
    height: $height--time-input;
    font-size: $font-size--base;
    background-color: $neutral-20;
    margin-right: $margin--tiny;
    padding: 0 $padding--small;
    text-align: right;
    font-weight: normal;
    border-radius: 4px;
    border: none;

    &:focus {
      outline: none;
      border: 1px solid $indigo-30;
    }
  }
}

::v-deep .v-popover,
::v-deep .trigger,
.content-button {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: $font-size--btn;
}

.empty-button {
  padding-left: 13px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
