



















































@import '~@tko/src/styles/variables/variables';
.table-cell-task-attachments {
  height: 28px;
  display: flex;
  align-items: center;
  margin-right: 12px;
  .attachments-count {
    display: flex;
    align-items: center;
    font-size: $font-size--h4;
    font-weight: $font-weight--normal;
    color: $color--text-tinted-dark;
    margin: 0px;

    .u-common-icon {
      margin-right: $margin--tiny;
      font-size: $font-size--btn;
    }
  }
}
.attachment-btn {
  position: relative;
  background: none;
  border: none;
  padding-left: 0px;
  padding-right: 0px;
}
