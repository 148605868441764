



















.layout-navigation {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.base-nav-part {
  flex: 0 0 auto;
  margin: 0 0 auto;
  position: relative;
}

.bottom-nav-part {
  flex: 0 0 auto;
  position: relative;
  margin: auto 0 0;
}

.base-scrolling-part {
  flex: 1 1 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow: auto;
  overflow: overlay;

  &::-webkit-scrollbar {
    width: 5px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #2f3553;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
}
