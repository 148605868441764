















































































































































































@import '~@tko/src/styles/variables/variables';
@import '@tko/src/styles/variables/colors/_branding.colors.scss';

.custom-field-input {
  width: 100%;
  height: 100%;
  display: flex;
  &.table-cell-input {
    align-items: center;
    padding: $padding--medium;
    justify-content: center;
  }
  &.multiline {
    position: relative;
  }

  div {
    width: 100%;
    &.has-tooltip {
      max-height: 34px;
    }

    textarea {
      height: 34px;
      overflow: hidden;
      &:hover {
        &::-webkit-scrollbar-thumb {
          background-color: rgba(66, 82, 110, 0.36);
        }
      }
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 6px;
      }
    }
  }

  input,
  textarea {
    width: 100%;
    padding: 7px 10px;
    border-radius: 4px;
    outline: none;
    border: 1px solid transparent;
    cursor: pointer;
    background-color: transparent;
    resize: none;
    &:focus {
      cursor: text;
    }
    &.tasks-table {
      &:focus,
      &:hover {
        border: 1px solid #f5f7fa;
      }
      &:focus {
        cursor: text;
        background-color: #fff;
      }
      &:not(:focus) {
        color: black;
        // for whatever reason numbers were showing as gray in TasksTableTasks otherwise.
      }
      &:not(:focus)::first-line {
        color: $color--text-tinted-dark;
      }
    }
    &.task-details {
      width: 100%;
      padding: 7px 10px;
      margin-left: -11px;
      &:hover {
        background: #f5f7fa;
      }
      &:focus {
        background: #f5f7fa;
        border: 1px solid #4461d7;
        box-sizing: border-box;
        border-radius: 4px;
      }
    }
    &.disabled {
      cursor: not-allowed;
    }
  }

  input::-webkit-outer-spin-button, /* Hide Arrows From Input Number*/
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &__placeholder {
    width: 100%;
    height: 100%;
    color: #646d7d;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    .empty-state-icon {
      color: $table-cell--blank-slate-color;
    }
    button {
      background: none;
      display: none;
      color: #4461d7;
      border: none;
      text-align: center;
      font-size: 14px;
      border-radius: $border-radius--default;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
    .empty-button {
      width: 100%;
      height: 100%;
      background-color: $white;
      color: $indigo-30;
      font-size: $font-size--btn;
      &.from-custom-field-modal {
        height: 30px;
        background-color: #f5f7fa;
      }
    }
  }
  &:hover {
    .custom-field-input__placeholder:not(.disabled) {
      .empty-state-icon {
        display: none;
      }
      button {
        display: block;
      }
    }
  }
}
