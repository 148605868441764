





























































































































$hover-color: #4461d7;

.priority-icon-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  &.active {
    color: $hover-color;
    .priority-icon {
      border: 1px solid $hover-color;
    }
  }
  &:hover {
    color: $hover-color;

    .priority-icon {
      border: 1px solid $hover-color;
    }
  }
}
.priority-icon {
  width: 24px;
  height: 24px;
  border: 1px solid #e1e6ee;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  margin-right: 8px;
  &.disabled {
    cursor: not-allowed;
  }

  svg {
    font-size: 13.5px;
    max-width: initial; // we need this to align it perfectly
  }
}

.table-cell-priority {
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
