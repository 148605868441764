


















































@import '~@tko/src/styles/variables/variables';
.table-cell-task-subtasks {
  display: flex;
  align-items: center;
  margin-right: 12px;
  height: 28px;
  svg {
    font-size: 12.5px;
    margin-right: $margin--small;
    margin-left: $margin--tiny;
  }
}
.subtask-btn {
  position: relative;
  background: none;
  border: none;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding-left: 0px;
  padding-right: 0px;
}
.subtask-number {
  font-size: $font-size--h4;
  font-weight: $font-weight--normal;
  color: $color--text-tinted-dark;
}
