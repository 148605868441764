
// Buttons
$color--disabled-button: #dddddd;
$color--btn-default: $color--text-default;
$background-color--btn-default: #fff;
$border-color--btn-default: $neutral-20;

$color--btn-primary: $white;
$background-color--btn-primary: $indigo-30;
$background-color-hover--btn-primary: $indigo-40;
$background-color-active--btn-primary: $indigo-50;
$background-color-focus--btn-primary: $indigo-30;

$color--btn-secondary: $indigo-40;
$background-color--btn-secondary: $white;
$background-color-hover--btn-secondary: $neutral-20;
$border-color--btn-secondary: $indigo-40;

$color--btn-tertiary: $color--text-tinted-dark;
$background-color--btn-tertiary: $white;
$background-color-hover--btn-tertiary: $neutral-20;
$background-color-focus--btn-tertiary: $white;
$background-color-active--btn-tertiary: $neutral-50;
$border-color--btn-tertiary: $white;
$border-color-focus--btn-tertiary: #6D6F80;


$color--btn-ongreybg: $color--text-tinted-dark;
$background-color--btn-ongreybg: $neutral-20;
$background-color-active--btn-ongreybg: $white;
$background-color-hover--btn-ongreybg: $white;
$border-color--btn-ongreybg: $neutral-20;
$border-color-hover--btn-ongreybg: $white;


$color--btn-highlight: $indigo-30;
$background-color--btn-highlight: $indigo-30--transparency-04;
$background-color-hover--btn-highlight: $indigo-30--transparency-12;
$background-color-active--btn-highlight: $indigo-30--transparency-20;
$background-color-focus--btn-highlight: $indigo-30--transparency-24;
$border-color--btn-highlight: $indigo-30;

$color--btn-info: #fff;
$background-color--btn-info: $color--info;
$border-color--btn-info: $color--info;

$color--btn-success: #fff;
$background-color--btn-success: $color--success;
$border-color--btn-success: $color--success;

$color--btn-warning: #fff;
$background-color--btn-warning: $color--warning;
$border-color--btn-warning: $color--warning;

$color--btn-danger: #fff;
$background-color--btn-danger: $red-30;
$background-color-hover--btn-danger: $red-40;
$background-color-active--btn-danger: $red-50;

$color--btn-navy: $color--text-default;
$background-color--btn-navy: $background-color--dark;
$border-color--btn-navy: $background-color--dark;

$color--btn-dark: $color--text-default;
$background-color--btn-dark: #fafafa;
$border-color--btn-dark: #fafafa;

$color--btn-dark-gray: $color--text-default;
$background-color--btn-dark-gray: #edf0f5;
$border-color--btn-dark-gray: #edf0f5;
