/*
# Product Tour Section structure
*/
// sass-lint:disable-all
@import '~@tko/src/styles/variables/colors/colors';
@import '~@tko/src/styles/variables/dimensions/margins-paddings.dimensions';

//  Variables
//  ----------
$width--s-product-tour__block: 383px;
$height--s-product-tour__block: 600px;

//  Structure
//  ----------

.s-product-tour {
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 0 20px 20px 0;
  z-index: 199;

  &.hasPubble {
    right: 80px;
  }

  //  Elements
  //  ----------

  &__card {
    display: flex;
    background-color: $white;
    width: $width--s-product-tour__block;
    min-height: $height--s-product-tour__block;
    box-shadow: 0 2px 16px 0 #b9bec6;
    border-radius: 4px;
    position: relative;
  }

  &__head {
    flex: 0 0 auto;
    position: absolute;
    right: 0;
    top: 0;
    padding: $padding--medium;
    z-index: 2;
  }

  &__head-btn {
    padding: 0;
    margin: 0;
    width: 24px;
    height: 24px;
    border: 0;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    color: #111;
    border-radius: 50%;

    svg {
      margin-right: 0;
      fill: currentColor;
    }

    &:hover {
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4);
    }
  }

  &__steps-holder {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    width: 100%;
    position: relative;
    z-index: 1;
  }

  &__steps-holder > div {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 25px;
  }

  &__image-hold {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 220px;
    flex: 0 0 auto;
  }

  &__content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding: 14px 0 0;
    max-height: 270px;
    height: 100%;
  }

  &__footer {
    width: 100%;
    padding-top: $padding--large;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    margin: auto 0 0;
  }

  .btn-primary {
    height: 40px;
    display: inline-block;
    text-align: center;
    background-color: #4461d7;
    border-color: #4461d7;
    font-size: 14px;

    &:hover {
      background-color: darken(#4461d7, 10%);
      border-color: darken(#4461d7, 10%);
    }
  }
}

.prev {
  color: #4461d7;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    color: darken(#4461d7, 10%);
  }
}

.description {
  flex: 1 1 auto;
  font-size: 14px;
  line-height: 24px;
  max-height: 220px;
  overflow-y: scroll;
  -webkit-mask-image: linear-gradient(180deg, #000 80%, transparent);
  mask-image: linear-gradient(180deg, #000 80%, transparent);
}

.description--scrolled{
  -webkit-mask-image: none;
  mask-image: none;
}

.w-dots-navigation {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  list-style: none;
  padding: 0;
  margin: 0 auto 0 0;

  > li {
    margin-right: $margin--medium;

    &:last-child {
      margin-right: 0;
    }
  }

  &__dot {
    display: block;
    background-color: #ccc;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    cursor: pointer;
  }

  .is-active &__dot {
    background-color: #4461d7;
  }
}

.slide-fade-enter-active {
  transition: all 0.22s ease-in-out;
}

.slide-fade-leave-active {
  transition: all 0.14s ease-in-out;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

.list-enter-active,
.list-leave-active {
  transition: all 280ms;
}
.list-enter {
  opacity: 0;
  transform: translateX(25px);
}

.list-leave-to {
  opacity: 0;
  transform: translateX(-25px);
}
