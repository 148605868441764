
























@import '~@tko/src/styles/variables/variables';

.w-empty-state-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: $table-cell--blank-slate-color;

  &.left {
    padding-left: 13px;
    justify-content: flex-start;
  }
  &.reset {
    padding-left: 0px;
  }
}
