@import '~@tko/src/styles/variables/variables';
@import '~@tko/src/styles/mixins/mixins';
$iconColor: #000;
$activeColor: #4461d7;
$lightGray: #e7ebf1;
$placeholderFadedColor: #6d6f80;
$inputTextColor: #0b0e1f;

.w-inline-search {
  $bg: #fff;
  font-size: 14px;
  position: relative;
  display: flex;
  line-height: 20px;
  align-items: center;
  border-radius: 64px;
  background-color: $bg;
  height: 32px;
  min-width: 235px;
  padding: 0 12px;
  border: 1px solid $lightGray;

  &.no-border {
    border: none;
  }

  &--active {
    border: 1px solid $activeColor;
  }

  &__icon {
    font-size: 12px;
  }

  &__input {
    width: 100%;
    background-color: $bg;
    padding-left: 8px;
    border: none;
    cursor: pointer;
    outline: none;
    overflow: hidden;
    color: $inputTextColor;

    &:placeholder-shown + .w-inline-search__clear-button {
      opacity: 0;
    }

    &:focus,
    &:not(:placeholder-shown) {
      cursor: initial;
    }

    &.has-faded-placeholder {
      &::placeholder {
        transition: color ease 0.2s;
      }

      &:focus {
        &::placeholder {
          color: $placeholderFadedColor;
        }
      }
    }
  }

  &__clear-button {
    display: block;
    background-color: $bg;
    border: none;

    svg {
      pointer-events: none;
      width: 8px;
    }

    &:hover {
      transform: scale(1.1);
    }
  }
}

$inline-search-colors: (
  iconColor: $iconColor,
  activeColor: $activeColor,
);

:export {
  @each $name, $color in $inline-search-colors {
    #{$name}: $color;
  }
}
