
































































































































input:focus-within + span::after {
  content: '';
  background-color: var(--token-c-checkbox-color-border);
}
input:focus-within:checked + span::after {
  background-color: var(--token-c-checkbox-color-active-strongest);
}
