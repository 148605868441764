// sass-lint:disable no-mergeable-selectors
// *
// *
//  Blank Slate
// *
// *

// sass-lint:disable clean-import-paths
@import '~@tko/src/styles/variables/variables';

//  Variables
//  ----------

$max-width--blank-slate: 440px;

$self: '.u-blank-slate';

//  Structure
//  ----------
.u-blank-slate {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: $margin--x-large 0;
    padding: $padding--x-large 0;
    background-repeat: no-repeat;
    background-position: center top;
}

//  Elements
//  ----------

.u-blank-slate {
    &__holder {
        max-width: $max-width--blank-slate;
    }

    &__image {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: $margin--xx-large;

        &--is-icon {
            font-size: 50px;
            padding: 25px;
        }
    }

    &__text {
        padding: 0;
        margin-bottom: $margin--xx-large;
        text-align: center;

        h3 {
            font-size: 20px;
            line-height: 22px;
            font-weight: $font-weight--semibold;
            color: #0b0e1f;
            margin-bottom: 0;
        }

        p {
            margin: $margin--medium auto 0;
            color: $color--text-secondary;
            font-size: $font-size--btn;
            line-height: 22px;
            color: #0b0e1f;
        }
    }

    &__text-title {
        font-size: 20px;
        font-weight: $font-weight--semibold;
        color: #0b0e1f;
        margin: 0;
    }

    &__action {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .btn-lg {
            overflow: hidden;
            text-overflow: ellipsis;
        }

        p {
            font-size: $font-size--default;
            margin: $margin--medium auto 0;
            color: $color--text-alt;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}

.u-blank-slate-grid-view {
    margin-top: 150px;
}

//  Structure Modified
//  ----------
#{$self}--is-in-popover {
    margin: 10px 0 0 0;
    padding: 10px;

    #{$self}__holder {
        max-width: 420px;
    }

    #{$self}__text-title {
        font-size: $font-size--x-large;
    }

    &.tasks-search-results__list--empty {
        #{$self}__holder {
            max-width: 500px;
        }
    }
}
