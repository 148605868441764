



































































































































































































































































































































































































































































@import '~@tko/src/styles/variables/variables';
@import '~@tko/src/styles/mixins/mixins';
@import '~@tko/src/styles/variables/dimensions/_typography.dimensions';
@import '~@tko/src/styles/variables/dimensions/_margins-paddings.dimensions';

.notifications-list-item {
  user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  margin-bottom: 4px;
  position: relative;
  border-radius: 4px;
  content-visibility: auto;

  &:last-of-type {
    margin-bottom: 16px;
  }
}

a.notifications-list-item {
  &:hover {
    text-decoration: none;
  }
}

.notifications-list-item--background-change {
  &:hover {
    cursor: pointer;
    background-color: #f5f7fa; // Use 'Neutral 10' design token when available
  }
}

.notifications-list-item__avatar {
  position: relative;
  width: 40px;
  height: 40px;
  margin-right: 16px;
}

.notifications-list-item__avatar-icon {
  box-shadow: 0px 0px 8px rgba(26, 26, 26, 0.1);
  border: 1px solid #ffffff; // Use 'White' design token when available
  position: absolute;
  bottom: -4px;
  right: -4px;
}

.notifications-list-item__text {
  flex-grow: 1;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60px;
  min-width: 0;
  margin-right: 16px;
}

.notifications-list-item__text-1 {
  height: 20px;
  display: flex;
  align-items: center;
  color: #0b0e1f; // Use 'Neutral 60' design token when available
}

.notifications-list-item__text-1-inner {
  display: flex;
  position: relative;
}

.notifications-list-item__text-1-text {
  color: #646d7d; // Use 'Neutral 50' design token when available
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 220px;
  margin-right: 8px;
}

.notifications-list-item__text-1-quick-view {
  position: absolute;
  right: -28px;
  top: -4px;
}

.notifications-list-item__text-2 {
  height: 20px;
  color: #0b0e1f; // Use 'Neutral 60' design token when available
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;

  a {
    color: inherit;
  }

  .notification-icon {
    padding-right: 2px;
  }
}

.notifications-list-item--unread {
  .notifications-list-item__text-1-text,
  .notifications-list-item__text-2 {
    font-weight: 600;
  }
}

.notifications-list-item__text-3 {
  height: 20px;
  margin-bottom: 0;
  color: #646d7d; // Use 'Neutral 50' design token when available
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notifications-list-item__meta {
  display: flex;
  height: 60px;
  align-items: flex-start;
  flex: 0 0 auto;
}

.notifications-list-item__meta-inner {
  display: flex;
  align-items: center;
  height: 20px;
}

.notifications-list-item__meta-time {
  display: inline-flex;
  color: #646d7d; // Use 'Neutral 50' design token when available
  font-style: normal;
  font-weight: normal;
  font-size: $font-size--small;
  line-height: 1;
  flex: 0 0 auto;
}

.notifications-list-item__meta-status {
  display: inline-flex;
  margin-left: 8px;
}

.notifications-list-item__meta-status-button {
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  width: 14px;
  height: 14px;
}

.notifications-list-item__meta-status-icon {
  width: 14px;
  height: 14px;
  max-width: 14px;
  transition-property: transform, fill;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  fill: #4461d7; // Use 'Indigo 40' design token when available

  &:hover {
    transform: scale(1.2);
    fill: #3c55bd; // Use 'Indigo 50' design token when available
  }
}

a {
  &:hover {
    text-decoration: underline;
  }
}

.notifications-list-item--read {
  .notifications-list-item__meta-status-icon {
    fill: #646d7d; // Use 'Neutral 50' design token when available

    &:hover {
      fill: #8f9bb3; // Use 'Neutral 40' design token when available
    }
  }
}

.notifications-list-item--loading {
  background-color: transparent;

  .notifications-list-item__avatar {
    background: transparent;

    .w-avatar {
      @include skeleton(true);

      background: transparent !important;

      ::v-deep img {
        display: none;
      }
    }

    .w-entity-icon {
      @include skeleton(true);

      position: absolute;
      border: none;
      background: transparent !important;
    }
  }

  .notifications-list-item__text-1 {
    @include skeleton(true);

    width: 33%;
  }

  .notifications-list-item__text-2 {
    @include skeleton(true);

    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .notifications-list-item__text-3 {
    @include skeleton(true);

    width: 66%;
  }

  .notifications-list-item__meta-time {
    @include skeleton(true);

    width: 50px;
    height: 100%;
  }

  .notifications-list-item__meta-status-button {
    @include skeleton(true);

    border-radius: 999999px;

    svg {
      opacity: 0;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  opacity: 1;
  transition: opacity 150ms ease-in-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
