// Margins
// ----------

$margins: (
    tiny: 2px,
    small: 4px,
    medium: 8px,
    large: 16px,
    x-large: 20px,
    xx-large: 24px
);

$margin--tiny: 2px;
$margin--small: 4px;
$margin--medium: 8px;
$margin--large: 16px;
$margin--x-large: 20px;
$margin--xx-large: 24px;


// Paddings
// ----------

$paddings: (
    tiny: 2px,
    small: 4px,
    medium: 8px,
    large: 16px,
    x-large: 20px,
    xx-large: 24px
);

$padding--tiny: 2px;
$padding--small: 4px;
$padding--medium: 8px;
$padding--large: 16px;
$padding--x-large: 20px;
$padding--xx-large: 24px;

$padding--xs-vertical: 1px;
$padding--xs-horizontal: 4px;
$padding--small-vertical: 4px;
$padding--small-horizontal: 8px;
$padding--default-vertical: 4px;
$padding--default-horizontal: 12px;
$padding--large-vertical: 8px;
$padding--large-horizontal: 16px;
$padding--x-large-vertical: 16px;
$padding--x-large-horizontal: 20px;
